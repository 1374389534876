import React from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import CardLessonplan from '../card-lesson-plan';

export default () => {
  const lessonplan = useSelector((state) => state.lessonplan);

  if (lessonplan.lessonplan.length > 0) {
    const ccp = lessonplan.lessonplan.map((c) => (
      <CardLessonplan key={uuid()} lessonplan={c} />
    ));
    return ccp;
  }
  return (
    <div style={{ fontSize: '0.75rem' }}>Nenhum plano de aula encontrado.</div>
  );
};


