import React from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import TextField from '../../../components/text-input';
import { Container, Input } from './styled';
import bncc from '../../../utils/bnccobj';
import { getLessonplan } from '../../../../state/reducers/lessonplan/index';
import filterSearch from '../../../utils/filterSearch';

export default () => {
  const dispatch = useDispatch();

  return (
    <Container>
      <Input
        id={uuid()}
        options={bncc}
        getOptionLabel={(option) => option.content}
        filterOptions={(option, state) => {
          if (state.inputValue.length > 5) {
            return option.filter((o) => filterSearch(state.inputValue, o));
          }
          return [];
        }}
        onInputChange={(v, t) => {
          if (t.length > 10) {
            const regex = '[A-Z]{2}[0-9]{2}[A-Z]{2}[0-9]{2}';
            if (t.match(regex)) {
              dispatch(getLessonplan(t.match(regex)[0]));
            }
          }
        }}
        renderInput={(params) => (
          <TextField
            id={uuid()}
            {...params}
            label="Entre com o código da BNCC ou uma palavra chave. Ex.: ef01ma03"
          />
        )}
      />
    </Container>
  );
};
