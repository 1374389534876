/* eslint-disable no-underscore-dangle */
import React from 'react';
import Loadable from '@loadable/component';
import styled from 'styled-components';

const EventTitle = Loadable(() => import('./event-title'));
const MenuEventContent = Loadable(() => import('./menu-event-content'));

const Root = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden; /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
`;

// :hover svg {
//   background-color: #1a252f;
// }

export default ({ data }) => (
  <Root>
    <MenuEventContent data={data} />
    <EventTitle data={data} />
  </Root>
);
