import initialState from './states';
import SET_MENU_LIST from './consts';

export default (state = initialState, action) => {
  // console.log(!state.menulist);
  switch (action.type) {
    case SET_MENU_LIST:
      return {
        ...state,
        menulist: !state.menulist,
      };

    default:
      return state;
  }
};
