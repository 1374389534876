import React from 'react';
import  makeStyles  from '../../../base-components/make-styles'
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { getLessonplanView } from '../../../../state/reducers/lessonplan';
import { Container, Card } from './styled';
import ViewLessonplan from '../../components/view-lesson-plan';
import ModalInsertComment from '../../../components/modal-insert-comment';
import Typography from '../../../components/typography';
import CardHeader from '../../../base-components/card-header'
import CardContent from '../../../base-components/card-content'
import CardActions from '../../../base-components/card-actions'
import Collapse from '../../../base-components/collapse'
import IconButton from '../../../base-components/icon-button'
import Rating from '../../../base-components/rating'
import ExpandMoreIcon from '../../../base-components/expand-more-icon'

const useStyles = makeStyles((theme) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

export default ({ lessonplan }) => {
  const dispatch = useDispatch();

  const view = useSelector((state) => state?.lessonplan[`${lessonplan.uuid}`]);

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [value] = React.useState(
    lessonplan?.comments?.reduce((a, n) => a + n.score, 0),
  );

  const handleExpandClick = () => {
    if (view === undefined) {
      dispatch(getLessonplanView(lessonplan.uuid));
    } else {
      setExpanded(!expanded);
    }
  };

  const viewLessonplan = () =>
    view && (
      <>
        <ViewLessonplan key={uuid()} view={view[0]} />
        <ModalInsertComment lessonplanuuid={view[0].uuid} />
      </>
    );

  return (
    <Container>
      <Card>
        <CardHeader
          title={lessonplan.title}
          subheader={`${lessonplan.class}, ${lessonplan.subject} `}
        />
        <CardActions disableSpacing>
          <Rating readOnly value={value / lessonplan.comments.length} />

          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="Visualizar"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>{viewLessonplan()}</CardContent>
        </Collapse>
        {expanded && (
          <>
            <span>
              <Typography>
                Voltar ao Top{' '}
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                  })}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="Visualizar"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Typography>
            </span>
          </>
        )}
      </Card>
    </Container>
  );
};
