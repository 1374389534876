export const VIEW_CALENDAR_EVENTS = 'VIEW_CALENDAR_EVENTS';

export const VIEW_CALENDAR_EVENT = 'VIEW_CALENDAR_EVENT';

export const INSERT_CALENDAR_EVENTS = 'INSERT_CALENDAR_EVENTS';

export const MODIFY_CALENDAR_EVENTS = 'MODIFY_CALENDAR_EVENTS';

export const REMOVE_CALENDAR_EVENTS = 'REMOVE_CALENDAR_EVENTS';

export const FLAG_CALENDAR_EVENTS = 'FLAG_CALENDAR_EVENTS';

export const FLAG_CALENDAR_EVENTS_GET = 'FLAG_CALENDAR_EVENTS_GET';

export const FLAG_CALENDAR_EVENTS_POST = 'FLAG_CALENDAR_EVENTS_POST';

export const FLAG_CALENDAR_EVENTS_PUT = 'FLAG_CALENDAR_EVENTS_PUT';

export const FLAG_CALENDAR_EVENTS_DELETE = 'FLAG_CALENDAR_EVENTS_DELETE';

export const SET_VISIBLE_MENU = 'SET_VISIBLE_MENU';

export const SET_VISIBLE_MENU_EVENT = 'SET_VISIBLE_MENU_EVENT';

export const SET_VISIBLE_MENU_EVENT_XYPOS = 'SET_VISIBLE_MENU_EVENT_XYPOS';

export const SET_TITLE = 'SET_TITLE';

export const SET_BACKGROUND_COLOR = 'SET_BACKGROUND_COLOR';

export const SET_TEXT_COLOR = 'SET_TEXT_COLOR';

export const SET_START_STR = 'SET_START_STR';

export const SET_END_STR = 'SET_END_STR';
