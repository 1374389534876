import styled from 'styled-components';
import C from '../../../base-components/card'
import I from '../../../base-components/autocomplete'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin-top: 24px;
`;

export const Input = styled(I)`
  div {
    /* border-radius: 24px; */
  }
  label {
    margin-left: 4px;
  }
`;

export const Card = styled(C)`
  margin-top: 8px;
  padding: 8px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  /* line-height: 19px; */
  /* identical to box height */

  border: 1px solid rgba(0, 0, 0, 0.1 7);

  border-radius: 4px;

  .MuiCardHeader-title {
    font-size: 1rem;
  }
  .MuiCardHeader-subheader {
    font-size: 0.75rem;
  }
  .MuiCardContent-root {
    padding: 0px;
  }
`;
