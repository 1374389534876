import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid from '../../../components/datagrid';
import { getUserLessonplan } from '../../../../state/reducers/user';
import Card from '../../../components/card';
import Container from '../../../components/container';
import columns from './columns';

export default () => {
  const dispatch = useDispatch();
  React.useMemo(() => {
    dispatch(getUserLessonplan());
  }, [dispatch]);
  const lessonplan = useSelector((state) => state.user.lessonplan);

  const l = lessonplan.map((o) => {
    const r = {
      id: o.uuid,
      uuid: o.uuid,
      name: o.name,
      title: o.title,
    };
    return r;
  });

  return lessonplan.length > 0 ? (
    <Container>
      <Card>
        <DataGrid
          title="Seus planos de aulas cadastrados:"
          rows={l}
          columns={columns}
        />
      </Card>
    </Container>
  ) : (
    <Container>
      <Card>
        <div>
          <i>Você não criou nenhum plano de aula ainda.</i>
        </div>
      </Card>
    </Container>
  );
};
