import React from 'react';
import Rating from '../../../base-components/rating';
import { v4 as uuid } from 'uuid';
import CardHeader from '../../../base-components/card-header';
import Typography from '../../../components/typography';
import Card from '../../../components/card-comment';
import moment from '../../../components/moment';

export default ({ view }) => (
  <>
    <Typography paragraph>
      <i>Comentários:</i>
    </Typography>

    {view?.comments.map((c) => (
      <Card
        key={uuid()}
        style={{ border: '0.2px solid', borderStyle: 'dotted' }}
      >
        <CardHeader
          // avatar={<Avatar alt="Foto Perfil" src={user.picture} />}
          title={
            <>
              <p style={{ fontSize: '0.75rem' }}>
                {c.guest ? 'Desconhecido' : `${c.name} ${c.surname}`}
              </p>
              <p style={{ fontSize: '0.75rem' }}>
                <Rating
                  style={{ fontSize: '0.75rem' }}
                  readOnly
                  value={c.score}
                />{' '}
                <span style={{ fontSize: '0.75rem' }}>
                  {moment(c.created_at).format('ll')}
                </span>
              </p>
            </>
          }
          subheader={<p style={{ fontSize: '0.75rem' }}>{c.content}</p>}
        />
      </Card>
    ))}
  </>
);
