/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import { call, put, select } from 'redux-saga/effects';
import * as api from './api';

export default function* getEffectSaga(action) {
    try {
        yield put({ type: action.flag }); // set status true

        const state = yield select();

        const { jwt } = state.auth;
        // get data api from axios get
        const { data } = yield call(api.apiGet, action.path, jwt);
        if (data.code === 1) {
            yield put({
                type: action.subtype,
                payload: data.data,
                code: data.code,
                msg: data.message,
            });
        }
        // return alert(data.message);
        return true;
    } catch (e) {
        alert(e);
    }
}
