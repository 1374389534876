import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducers from './combineReducers';

import { rootSaga } from './sagas';

const sagaMiddleware = createSagaMiddleware();

export default createStore(
  rootReducers,
  compose(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);
