/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import { call, put } from 'redux-saga/effects';
import * as api from './api';

// Login
export default function* login(action) {
    try {
        // get data api from axios post
        const { data } = yield call(api.apiPost, action.path, action.payload);

        if (data.code === 1) {
            yield put({ type: action.subtype, payload: data });
        } else {
            yield put({
                type: 'ERROR_MSG',
                payload: 'Campo Usuário ou Senha: Inválido.',
            });
        }
        return alert(data.message);
    } catch (e) {
        alert(e);
    }
}
