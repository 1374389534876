import React from "react";
import { v4 as uuid } from 'uuid';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import AddIcon from '../../../../../base-components/add-icon';

const AddStrategiesFase = ({ setStrategies, strategies }) => {
    const insert = () => {
        const nova = {
            step: strategies.length + 1,
            time: `1 minuto(s).`,
            guidance: '',
            purpose: '',
            dialogue: [],
            resources: [],
        };

        return [...strategies, nova];
    };
    return (
        <div>
            <Tooltip title="Adicionar Fase" placement="right">
                <IconButton
                    id={uuid()}
                    onClick={() => setStrategies(insert())}
                    aria-label="adicionar"
                >
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default AddStrategiesFase;