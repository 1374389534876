import React from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import filterSearch from '../../utils/filterSearch';
import ContainerAutcomplete from '../container-autocomplete';
import InputAutocomplete from '../input-autocomplete';
import setLessonplanHeader from './redux';
import TextField from '../text-input';

export default ({ school }) => {
  const dispatch = useDispatch();
  const schools = useSelector((state) => state.user.school);
  const [s, setSchool] = React.useState({ name: school });

  return (
    <ContainerAutcomplete id={uuid()}>
      <InputAutocomplete
        id={uuid()}
        filterSelectedOptions
        options={schools}
        getOptionLabel={(option) => option.name}
        filterOptions={(option, state) => {
          if (state.inputValue.length > 3) {
            return option.filter((o) => filterSearch(state.inputValue, o));
          }
          return { name: 'vazio' };
        }}
        onChange={(event, newValue, reason) => {
          if (reason === 'select-option') {
            dispatch(setLessonplanHeader(newValue));
            setSchool(newValue);
          }
        }}
        renderInput={(param) => (
          <TextField id={uuid()} {...param} label="Procurar uma Escola..." />
        )}
        fullWidth
        value={s}
      />
    </ContainerAutcomplete>
  );
};
