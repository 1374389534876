export const FLAG_USER_GET = 'FLAG_USER_GET';

export const FLAG_USER_POST = 'FLAG_USER_POST';

export const FLAG_USER_PUT = 'FLAG_USER_PUT';

export const FLAG_USER_DEL = 'FLAG_USER_DEL';

export const FLAG_USER_SCHOOL_GET = 'FLAG_USER_SCHOOL_GET';

export const FLAG_USER_SCHOOL_POST = 'FLAG_USER_SCHOOL_POST';

export const FLAG_USER_SCHOOL_PUT = 'FLAG_USER_SCHOOL_PUT';

export const FLAG_USER_SCHOOL_DEL = 'FLAG_USER_SCHOOL_DEL';

export const GET_USER_LESSONPLAN = 'GET_USER_LESSONPLAN';

export const GET_USER_CALENDAR = 'GET_USER_CALENDAR';

export const GET_USER = 'GET_USER';

export const GET_USER_SCHOOL = 'GET_USER_SCHOOL';

export const POST_USER_SCHOOL = 'POST_USER_SCHOOL';

export const POST_USER_CALENDAR = 'POST_USER_CALENDAR';

export const PUT_USER_SCHOOL = 'PUT_USER_SCHOOL';

export const PUT_USER_CALENDAR = 'PUT_USER_CALENDAR';

export const PUT_USER = 'PUT_USER';

export const DEL_USER_SCHOOL = 'DEL_USER_SCHOOL';

export const DEL_USER_CALENDAR = 'DEL_USER_CALENDAR';
