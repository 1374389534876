import React from 'react';
import { Router } from '@reach/router';
import Loadable from '@loadable/component';
import { PublicRoute } from '../../routes';
import LazyLoading from '../../app/components/lazy-loading';
import * as Public from '../../app/public';

const NotFound = Loadable(() => import('../404'), {
    fallback: <LazyLoading />,
});

const IndexPage = () => (
    <Router basepath="/info">
        <NotFound path="*" />
        <PublicRoute
            path="/terms"
            component={Public.Terms}
            title="PA - Termos de Uso"
        />
        <PublicRoute
            path="/privacity"
            component={Public.Privacity}
            title="PA - Política de Privacidade"
        />
        <PublicRoute
            path="/ads"
            component={Public.Ads}
            title="PA - Publicidades"
        />
        <PublicRoute
            path="/about"
            component={Public.About}
            title="PA - Quem Somos?"
        />
    </Router>
);

export default IndexPage;
