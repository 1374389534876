import React from "react";
import { v4 as uuid } from 'uuid';
import TextField from '../../../../../components/text-input';

const OrientacaoTextFieldStrategiesFase = ({ setEdtGuidance, edtguidance }) => {
    return (
        <TextField
            id={uuid()}
            label="Orientação"
            value={edtguidance}
            onChange={(e) => setEdtGuidance(e.target.value)}
        />
    )
}

export default OrientacaoTextFieldStrategiesFase;