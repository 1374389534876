import * as actions from './consts';

export const getComments = () => ({
    type: 'API_METHOD_GET',
    subtype: actions.GET_COMMENTS,
    flag: actions.FLAG_COMMENTS_GET,
    path: '/comment',
});

// export const getCommentsView = (uuid) => ({
//   type: 'API_METHOD_GET',
//   subtype: actions.GET_COMMENTS_VIEW,
//   flag: actions.FLAG_COMMENTS_GET_VIEW,
//   path: `/comment/${uuid}/view`,
//   uuid,
// });

export const postComments = (payload) => ({
    type: 'API_METHOD_POST',
    subtype: actions.POST_COMMENTS,
    flag: actions.FLAG_COMMENTS_POST,
    state: 'comments',
    intercept: 'comments',
    path: '/comment',
    payload,
});

// export const postCommentsPrint = (payload) => ({
//   type: 'API_METHOD_POST',
//   subtype: actions.POST_COMMENTS_PRINT,
//   flag: actions.FLAG_COMMENTS_POST,
//   path: '/comments/print',
//   payload,
// });

// export const putComments = (uuid) => ({
//   type: 'API_METHOD_PUT',
//   subtype: actions.PUT_COMMENTS,
//   flag: actions.FLAG_COMMENTS_PUT,
//   path: `/comment/${uuid}`,
//   uuid,
// });

export const delComments = (uuid) => ({
    type: 'API_METHOD_DELETE',
    subtype: actions.DEL_COMMENTS,
    flag: actions.FLAG_COMMENTS_DEL,
    state: 'comments',
    intercept: 'comments',
    path: `/comment/${uuid}`,
    uuid,
});
