import React from "react";
import { v4 as uuid } from 'uuid';
import ChipsSubjects from '../../../../../components/autocomplete-chips-subjects';

const DisciplinaInputChipsLessonplan = ({ setSubject, subject }) => {
    return (
        <ChipsSubjects
            id={uuid()}
            label="Disciplina"
            subject={subject}
            setSubject={setSubject}
        />
    )
}

export default DisciplinaInputChipsLessonplan;