import React from "react";
import styled from "styled-components";
import Typography from "../../base-components/typography";
import { Link } from 'gatsby';

const LinkNav = styled((props) => (
    <Link to={props.to}>
        <Typography>{props.text}</Typography>
    </Link>
))``;

export default LinkNav;
