export default [
  // {
  //   name: 'Mapa do Site',
  //   route: '/sitemap.xml',
  // },
  {
    name: 'Política de privacidade',
    route: '/info/privacity',
  },
  {
    name: 'Termos de uso',
    route: '/info/terms',
  },
  {
    name: 'Escolha de anúncios',
    route: '/info/ads',
  },
  {
    name: 'Quem somos?',
    route: '/info/about',
  },
];
