import React from 'react';
import LazyLoad from 'react-lazy-load';
import { useSelector } from 'react-redux';
import { HomeContainer, Banner, TextBanner, Caption } from '../styled';
import Planning from './components/planning';
// import calendar from '../../../images/planning.svg';

export default () => {
  const isMobile = useSelector((state) => state.app.isMobile);
  return (
    <HomeContainer mobile={isMobile}>
      <Banner style={{ width: '100%' }}>
        <TextBanner>Seus planos de aulas</TextBanner>
        <TextBanner>em</TextBanner>
        <TextBanner>um só lugar.</TextBanner>
      </Banner>
      <Banner
        style={{
          width: '100%',
          // backgroundColor: '#ffffff',
        }}
      >
        <LazyLoad debounce={false} offsetVertical={600} threshold={0.97}>
          <Planning />
        </LazyLoad>
        <Caption>
          Temos um calendário para organizar seus planos de aula, assim fica
          muito mais fácil de encontrá-los.
        </Caption>
      </Banner>
    </HomeContainer>
  );
};
