import React from 'react';
import { v4 as uuid } from 'uuid';
import Comment from './comment';
import Typography from '../../../components/typography';

// TODO: Consultar BNCC passando o mouse por cima do código.

const ViewLessonplan = ({ view }) => (
  <div key={uuid()}>
    <Typography paragraph>
      <b>BNCC:</b>
    </Typography>
    <ul>
      {view.bncc.map((b) => (
        <li key={uuid()}>{b}</li>
      ))}
    </ul>
    <Typography paragraph>
      <b>Objetivo:</b> {view.hub}
    </Typography>
    <Typography paragraph>
      <b>Recursos:</b> {view.resources}
    </Typography>
    <Typography paragraph>
      <b>Estrategias</b>
    </Typography>
    {view.strategies.map((q) => (
      <div key={uuid()}>
        <Typography paragraph>
          <b>
            <i>{q.step}ª Fase:</i>
          </b>
        </Typography>
        <Typography paragraph>
          <i>Duração:</i> {q.time}
        </Typography>
        <Typography>
          <i>Orientação:</i>
        </Typography>
        <p
          style={{
            textIndent: '1.5em',
            textAlign: 'justify',
            textJustify: 'inter-word',
          }}
        >
          {q.guidance}
        </p>
        <Typography>
          <i>Propósito:</i>
        </Typography>
        <p
          style={{
            textIndent: '1.5em',
            textAlign: 'justify',
            textJustify: 'inter-word',
          }}
        >
          {q.purpose}
        </p>
        {q.dialogue.length > 0 && (
          <>
            <Typography paragraph>
              <i>Fale com a turma:</i>
            </Typography>
            <ul>
              {q.dialogue.map((p) => (
                <li key={uuid()}>{p}</li>
              ))}
            </ul>
          </>
        )}
        {q.resources.length > 0 && (
          <div key={uuid()}>
            <Typography paragraph>
              <i>Atividades complementares:</i>
            </Typography>
            <ul>
              {q.resources.map((k, r) => (
                <li key={uuid()}>
                  <a key={uuid()} href={k}>
                    Atividade {r + 1}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    ))}
    <Comment view={view} />
  </div>
);

export default ViewLessonplan;
