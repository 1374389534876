import moment from '../../../../components/moment';

export const insert = (uuid, plan, title, start, end, schoolschedule) => ({
  uuid,
  plan,
  title,
  start,
  end,
  schoolschedule,
});

// export const getThisDate = (data) => {
//   const d = moment(new Date(data.date)).format('YYYY-MM-DD');
//   const newdate = d.split('-');
//   return `${newdate[0]}-${newdate[1]}-${
//     data.dayNumberText.length === 1
//       ? `0${data.dayNumberText}`
//       : data.dayNumberText
//   }`;
// };

export const getThisDate = (date) =>
  moment(new Date(date)).add(1, 'days').format('YYYY-MM-DD');

export const update = (plan, title, start, end, schoolschedule) => ({
  plan,
  title,
  start,
  end,
  schoolschedule,
});
