import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import Modal from '../../../../components/modal';
import IconButton from '../../../../base-components/icon-button';
import Tooltip from '../../../../components/tooltip';
import { insertCalendarEvents } from '../redux';
import { Center } from '../../../../components/datagrid/styles/styled';
import { insert, getThisDate } from './utils';
import AutocompleteInputLessonplan from '../../../../components/autocomplete-input-lessonplan';
import { IconButtonDayContent } from './styled';
import AddBoxIcon from '../../../../base-components/add-box-icon';

export default function ({ data }) {
  const dispatch = useDispatch();
  const [handle, setHandle] = React.useState(false);
  const [ltitle, setTitle] = React.useState('');
  const [lid, setID] = React.useState('');
  const [schoolschedule] = React.useState([
    {
      url: '',
      name: '',
      dir: '',
      coord: '',
      time: '',
      prof: '',
      class: '',
      subject: '',
    },
  ]);
  const date = getThisDate(data.date);

  function SetAction() {
    return (
      <Tooltip title="Adicionar" placement="right-end">
        {/* Verifica lid e tittle diferente de null */}
        {!(lid !== '' && ltitle !== '') ? (
          <span />
        ) : (
          <IconButton
            aria-label="adicionar"
            onClick={() => {
              dispatch(
                insertCalendarEvents(
                  insert(uuidv4(), lid, ltitle, date, date, schoolschedule),
                ),
              );
              setHandle(false);
            }}
          >
            <AddBoxIcon />
          </IconButton>
        )}
      </Tooltip>
    );
  }

  const body = (
    <>
      <h3>Adicionar um Plano de Aula para este dia?</h3>
      <Center>
        <AutocompleteInputLessonplan setID={setID} setTitle={setTitle} />
      </Center>
    </>
  );

  const trigger = (
    <Tooltip title="Adicionar">
      <IconButtonDayContent
        aria-label="adicionar"
        onClick={() => setHandle(true)}
      >
        {data.dayNumberText.length === 1
          ? `0${data.dayNumberText}`
          : data.dayNumberText}
      </IconButtonDayContent>
    </Tooltip>
  );

  return (
    <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
      {body}
    </Modal>
  );
}
