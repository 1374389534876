import styled from 'styled-components';

export const Paper = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 400;
  background-color: #ffffff;
  border: '2px solid #000';
  box-shadow: 5pc;
  padding: 16px 32px 24px;
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > div {
    margin: 1px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
  }
  & > span {
    display: flex;
    padding: 32px;
  }
`;

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    margin: theme.spacing(1);
    /* // width: 'ch', */
  }
`;
