import React from 'react';
import Delete from './modal-delete';
import Link from '../../../../../components/link';
import Tooltip from '../../../../../base-components/tool-tip';
import EditIcon from '../../../../../base-components/edit-icon';
import LinkNav from '../../../../../components/linknav';
import IconButtonPdfEvent from '../../../../../components/icon-button-pdf-event';

export default ({ params }) => {
  const header = {
    url: "Logo Escola",
    name: "Nome Escola",
    dir: "Diretor(a)",
    coord: "Coordenador(a)",
    prof: "Professor(a)",
    date: Date.toString,
    time: "horario da sula",
  };

  return (
    <>
      <Delete params={params} />

      <Link
        to={`/app/lessonplan/${params.row.id}/edit`}
        text={
          <Tooltip title="Editar" placement="right-end">
            <EditIcon aria-label="editar" />
          </Tooltip>
        }
      />

      <LinkNav
        style={{ marginLeft: '0px' }}
        to={`/app/lessonplan/${params.row.id
          }/report/${btoa(JSON.stringify(header))}`}
        text={<IconButtonPdfEvent aria-label="visualizar" />}
      />
    </>
  )
}
