import React from 'react';
import styled from 'styled-components';
import Button from '../../base-components/button';

export default styled(({ ...props }) => <Button {...props} />)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #312D2A !important;
  margin-left: 8px;
  /* font-size: 0.75rem; */
  /* font-family: 'Roboto'; */
  /* font-weight: 400; */
  /* font-weight: 500; */
  // line-height: 1.75;
  letter-spacing: 0.00938em;
  text-transform: capitalize;
  :link,
  :visited {
    text-decoration: none;
  }
  :hover {
    // font-size: 1rem;
    color: #028090;
    text-decoration-style: wavy;
    text-decoration-color: darkturquoise;
  }

  p {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    color: rgba(0, 0, 0, 0.54);
  }
`;
