import initialState from './states';
import * as types from './consts';

export default (state = initialState, action) => {
    switch (action.type) {
    case types.IS_MOBILE:
        return { ...state, isMobile: action.value };

    case types.STATUS_PLANNING_TABS_PLAN:
        return { ...state, tabPlan: action.value };

    case types.STATUS_PLANNING_TABS_SCHOOL:
        return { ...state, tabSchool: action.value };

    case types.STATUS_PLANNING_TABS_SCHOOL_CLASS:
        return { ...state, tabSchoolClass: action.value };

    default:
        return state;
    }
};
