import React, { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { enquireScreen } from 'enquire-js';
import { setIsMobile } from '../../state/reducers/app/index';
import LazyLoading from '../../app/components/lazy-loading';
import Seo from '../../app/components/seo';

const AuthLayout = lazy(() => import('./AuthLayout'));

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ component: Component, title, location, ...rest }) => {
    const dispatch = useDispatch();
    const isLogged = useSelector((state) => state.auth.islogged);
    useMemo(() => {
        enquireScreen((b) => {
            dispatch(setIsMobile(!!b));
        });
    }, [dispatch]);


    // eslint-disable-next-line react/prop-types
    if (!isLogged && location.pathname !== '/app/') {
        navigate('/');
        return null;
    }

    
    return (
        <Suspense fallback={LazyLoading()}>
            <AuthLayout>
                <>
                    <Seo title={title} />
                    <Component {...rest} />
                </>
            </AuthLayout>
        </Suspense>
    );
};

PrivateRoute.defaultProps = {
    title: 'Plano de Aula',
};

PrivateRoute.propTypes = {
    title: PropTypes.string,
};

export default PrivateRoute;

//
