import React from 'react';
import Loadable from '@loadable/component';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonRect from '../../../../components/skeleton-rect';
import {
  modifyCalendarEvents,
  setDate,
  viewCalendarEventsByRange,
} from '../redux';
import {
  getUserLessonplan,
  getUserSchool,
  getUser,
} from '../../../../../state/reducers/user';
import { update, getThisDate } from './utils';

const DayCellContent = Loadable(() => import('./day-cell-content'), {
  fallback: <SkeletonRect />,
});
const EventContent = Loadable(() => import('./event-content'), {
  fallback: <SkeletonRect />,
});

function Calendar() {
  const dispatch = useDispatch();

  const calendar = useSelector((state) => state.calendar);

  const { locale, timeZone } = Intl.DateTimeFormat('pt-BR').resolvedOptions();

  const getCalendarData = (fetchInfo, successCallback) => {
    const newDate = {
      start: getThisDate(fetchInfo.start),
      end: getThisDate(fetchInfo.end),
    };

    if (
      getThisDate(fetchInfo.start) !== calendar?.date.start &&
      getThisDate(fetchInfo.end) !== calendar?.date.end
    ) {
      dispatch(setDate(newDate));

      dispatch(viewCalendarEventsByRange(newDate));
    }

    // failureCallback(() => 'Error');

    successCallback(calendar?.calendarevents);
  };

  React.useMemo(() => {
    dispatch(getUserLessonplan());
    dispatch(getUserSchool());
    dispatch(getUser());
  }, [dispatch]);

  return (

    <FullCalendar
      // plugins={[interactionPlugin, dayGridPlugin, bootstrapPlugin]}
      plugins={[interactionPlugin, dayGridPlugin]}
      timeZone={timeZone}
      locale={locale}
      height="auto"
      contentHeight="auto"
      aspectRatio={1}
      expandRows
      // updateSize parametro não existe
      handleWindowResize
      windowResizeDelay={90}
      // stickyHeaderDates
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'title',
        center: '',
        right: 'today prev,next',
      }}
      selectable
      editable
      droppable
      weekends
      dayMaxEvents
      titleFormat={{ year: 'numeric', month: 'long' }}
      slotLabelFormat={{
        hour: '2-digit',
        minute: '2-digit',
      }}
      columnHeaderFormat={{
        weekday: 'short',
        day: 'numeric',
        omitCommas: true,
      }}
      allDaySlot={false}
      buttonText={{
        today: 'hoje',
        month: 'mês',
        week: 'semana',
        day: 'dia',
        list: 'lista',
      }}
      themeSystem="standard"
      dayCellContent={(dcc) => <DayCellContent data={dcc} />}
      eventContent={(ec) => <EventContent data={ec} />}
      eventDrop={(info) => {
        dispatch(
          modifyCalendarEvents(
            // eslint-disable-next-line no-underscore-dangle
            info.event.extendedProps.uuid,
            update(
              info.event.extendedProps.plan,
              info.event.title,
              info.event.start,
              info.event.end || info.event.start,
              info.event.extendedProps.schoolschedule
            ),
          ),
        );
        return true;
      }}
      eventResize={(info) => {
        dispatch(
          modifyCalendarEvents(
            // eslint-disable-next-line no-underscore-dangle
            info.event.extendedProps.uuid,
            update(
              info.event.extendedProps.plan,
              info.event.title,
              info.event.start,
              info.event.end,
              info.event.extendedProps.schoolschedule
            ),
          ),
        );
        return true;
      }}
      events={(fetchInfo, successCallback, failureCallback) =>
        getCalendarData(fetchInfo, successCallback, failureCallback)
      }
    />

  );
}

export default Calendar;
