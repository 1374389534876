import styled from 'styled-components';
import container from '../base-components/container'

export const Container = styled(container)`
  min-height: 50vh;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  justify-content: center;
  /* align-items: center;
  flex-wrap: wrap; */
  /* background-color: #3C3C3C; */
  /* margin-top: 8px; */
`;

export const HomeContainer = styled.div`
  /* max-height: 50vh; */
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  justify-content: center;
  /* align-items: center;
  flex-wrap: wrap; */
  /* background-color: #f5f5f5; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* margin-top: 8px; */
`;

export const Banner = styled.div`
  top: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  /* margin: 1px; */
  min-height: 60vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: inherit;
    top: 0px;
    left: 0px;
    opacity: 1;
    transition: filterBrightness 2250ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
      filterSaturate 3000ms cubic-bezier(0.4, 0, 0.2, 1) 0s,
      opacity 1500ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  }
`;

export const TextBanner = styled.p`
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 3.25rem;
  /* // lineHeight: 49, */
  color: #3c3c3c;
  background-color: #ffffff;
`;

export const Caption = styled.p`
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 19px;
  text-align: justify;

  margin: 8px;

  color: #000000;
  background-color: #ffffff;
`;
