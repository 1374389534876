import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './src/state';

console.log(persistor.getState());
// eslint-disable-next-line react/display-name, react/prop-types, func-names
export default function ({ element }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
}
