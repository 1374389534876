/* eslint-disable react/display-name */
import React, { useMemo } from 'react';
import Loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { getUser } from '../../../state/reducers/user';

const Paneluser = Loadable(() => import('./panel-user'));
const LoadingPanelUser = Loadable(() => import('./panel-user/loading'));

export default () => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    useMemo(() => {
        if (Array.isArray(user.user) && !user.user.length) {
            dispatch(getUser());
        }
    }, [dispatch]);

    const loaduser = () => {
        if (user.user !== []) {
            return <Paneluser key={uuid()} user={user.user} />;
        }
        return <div>Alguma coisa deu errado, tente novamente mais tarde.</div>;
    };

    return (
        <>
            <br />
            {user.get ? <LoadingPanelUser /> : loaduser()}
        </>
    );
};
