import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataGrid from '../../../components/datagrid';
import { getComments } from '../../../../state/reducers/comments';
import Card from '../../../components/card';
import Container from '../../../components/container';
import columns from './columns';

export default () => {
  const dispatch = useDispatch();
  React.useMemo(() => {
    dispatch(getComments());
  }, [dispatch]);
  const comments = useSelector((state) => state.comments.comments);

  const c = comments.map((o) => {
    const r = {
      id: o.uuid,
      uuid: o.uuid,
      content: o.content,
      created_at: o.created_at,
      updated_at: o.updated_at,
      plan: o.plan,
      guest: o.guest,
      score: o.score,
    };
    return r;
  });

  return c.length > 0 ? (
    <Container>
      <Card>
        <DataGrid title="Seus comentários:" rows={c} columns={columns} />
      </Card>
    </Container>
  ) : (
    <Container>
      <Card>
        <div>
          <i>Você não criou nenhum plano de aula ainda.</i>
        </div>
      </Card>
    </Container>
  );
};
