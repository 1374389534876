export const UPDATE_STRATEGIES = 'UPDATE_STRATEGIES';

export const SET_EDIT = 'SET_EDIT';

export const SET_EDT_TIME = 'SET_EDT_TIME';

export const SET_EDT_GUIDANCE = 'SET_EDT_GUIDANCE';

export const SET_EDT_PURPOSE = 'SET_EDT_PURPOSE';

// insert

export const INSERT_STRATEGIES = 'INSERT_STRATEGIES';

// remove

export const REMOVE_STRATEGIES = 'REMOVE_STRATEGIES';
