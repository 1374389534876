import React from 'react';
import styled from 'styled-components';
import Loadable from '@loadable/component';
import { ThemeProvider } from '@mui/material/styles';
// import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import {
  DataGrid,
  GridToolbarContainer,
  // GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  // GridToolbarDensitySelector,
} from '@material-ui/data-grid';
import Typography from '../typography';
import theme from './styles/theme';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const RootDataGrid = Loadable(() => import('./styles'));

export default styled(({ title, rows, columns }) => (
  <RootDataGrid>
    <Typography>
      <i>{title}</i>
    </Typography>
    <ThemeProvider theme={theme}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        // checkboxSelection
        autoHeight
        headerHeight={36}
        rowHeight={36}
        localeText={{
          toolbarDensity: 'Size',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Small',
          toolbarDensityStandard: 'Medium',
          toolbarDensityComfortable: 'Large',
        }}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </ThemeProvider>
  </RootDataGrid>
))`
  div {
    div {
      width: 100%;
      & > * {
        width: 100%;
      }
    }
  }
`;
