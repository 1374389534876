import React from 'react';
import Card, { Container } from '../styles';
import Typography from '../../../../../base-components/typography';
import BarStrategiesTools from '../bar-strategies-tools';
import CardShowStrategies from '../card-show-strategies';

const Strategies = ({ s, strategies, setStrategies, setEdit }) => {

  return (
    <Container>
      <Card
        style={{
          border: '0.2px solid',
          borderColor: '#028090',
          padding: '16px',
        }}
      >
        <Typography paragraph>
          <b>
            <i>{s.step}ª Fase:</i>
          </b>
        </Typography>
        {/* BarStrategiesTools */}
        <BarStrategiesTools
          s={s}
          strategies={strategies}
          setStrategies={setStrategies}
          setEdit={setEdit}
        />
        <br />
        {/* CardShowStrategies */}
        <CardShowStrategies s={s} />
      </Card>
    </Container>
  );
};

export default Strategies;
