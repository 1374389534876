import React from "react";
import { v4 as uuid } from 'uuid';
import TextField from '../../../../../components/text-input';

const PropositoTextFieldStrategiesFase = ({ setEdtPurpose, edtpurpose }) => {
    return (
        <TextField
            id={uuid()}
            label="Propósito"
            value={edtpurpose}
            onChange={(e) => setEdtPurpose(e.target.value)}
        />
    )
}

export default PropositoTextFieldStrategiesFase;