import * as user from './consts';

import initialState from './initialStates';

export default (state = initialState, action) => {
    switch (action.type) {
    case user.GET_USER_LESSONPLAN:
        return {
            ...state,
            get: false,
            lessonplan: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.GET_USER_CALENDAR:
        return {
            ...state,
            get: false,
            calendar: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.GET_USER:
        return {
            ...state,
            get: false,
            user: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.GET_USER_SCHOOL:
        return {
            ...state,
            getSchool: false,
            school: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.POST_USER_SCHOOL:
        return {
            ...state,
            postSchool: false,
            school: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.POST_USER_CALENDAR:
        return {
            ...state,
            post: false,
            calendar: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.PUT_USER_SCHOOL:
        return {
            ...state,
            putSchool: false,
            school: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.PUT_USER_CALENDAR:
        return {
            ...state,
            put: false,
            calendar: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.PUT_USER:
        return {
            ...state,
            put: false,
            user: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.DEL_USER_SCHOOL:
        return {
            ...state,
            delSchool: false,
            school: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.DEL_USER_CALENDAR:
        return {
            ...state,
            put: false,
            calendar: action.payload || [],
            code: action.code,
            msg: action.msg,
        };

    case user.FLAG_USER_GET:
        return { ...state, get: true };

    case user.FLAG_USER_POST:
        return { ...state, post: true };

    case user.FLAG_USER_PUT:
        return { ...state, put: true };

    case user.FLAG_USER_DEL:
        return { ...state, del: true };

    case user.FLAG_USER_SCHOOL_GET:
        return { ...state, getSchool: true };

    case user.FLAG_USER_SCHOOL_POST:
        return { ...state, postSchool: true };

    case user.FLAG_USER_SCHOOL_PUT:
        return { ...state, putSchool: true };

    case user.FLAG_USER_SCHOOL_DEL:
        return { ...state, delSchool: true };

    default:
        return state;
    }
};
