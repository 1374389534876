import React from 'react';
import styled from 'styled-components';
import Loadable from '@loadable/component';
import { useDispatch, useSelector } from 'react-redux';
import { getUserSchool } from '../../../../state/reducers/user';
import CardContent from '../../../base-components/card-content';
import Card from '../../../components/card';
import Container from '../../../components/container';

const PainelEditSchool = Loadable(() => import('./panel-edit-school'));

function MySchool() {
  const dispatch = useDispatch();

  React.useMemo(() => {
    dispatch(getUserSchool());
  }, [dispatch]);
  const school = useSelector((state) => state.user.school);

  const s = school.map((o) => {
    const r = {
      id: o.uuid,
      uuid: o.uuid,
      url: o.url,
      name: o.name,
      dir: o.dir,
      coord: o.coord,
    };
    return r;
  });

  return (
    <Container>
      <Card>
        <CardContent>
          <PainelEditSchool school={s} />
        </CardContent>
      </Card>
    </Container>
  );
};

export default styled(MySchool)`
.MuiOutlinedInput-notchedOutline  {
  border-color: #028090;
}
`;