import React from 'react';
import Loadable from '@loadable/component';
import SkeletonRect from '../../../../components/skeleton-rect';

const Actions = Loadable(() => import('./actions'), {
  fallback: <SkeletonRect />,
});

export default [
  { field: 'id', headerName: 'UUID', width: 240 },
  {
    field: 'title',
    headerName: 'Assunto',
    width: 280,
  },
  {
    field: 'actions',
    headerName: 'Ações',
    width: 240,
    renderCell: (params) => <Actions params={params} />,
  },
];
