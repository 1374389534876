import React from "react";
import { v4 as uuid } from 'uuid';
import ChipsBncc from '../../../../../components/autocomplete-chips-bncc';

const BnccChipsLessonplan = ({ bncc, setBncc, strategies, setStrategies }) => {
    return (
        <ChipsBncc
            id={uuid()}
            label="Entre com o código da BNCC ou uma palavra chave. Ex.: ef01ma03"
            bncc={bncc}
            setBncc={setBncc}
            strategies={strategies}
            setStrategies={setStrategies}
        />
    )
}

export default BnccChipsLessonplan;