import initialState from './states';
import * as cases from './consts';

export default (state = initialState, action) => {
  switch (action.type) {
    case cases.SET_EDIT:
      return {
        ...state,
        components: {
          createlessonplan: {
            strategies: state.components.createlessonplan.strategies,
            edit: action.strategy,
          },
        },
      };

    case cases.SET_EDT_TIME:
      return {
        ...state,
        components: {
          createlessonplan: { edit: { time: action.time } },
        },
      };

    // insert
    case cases.INSERT_STRATEGIES:
      return {
        ...state,
        components: {
          createlessonplan: {
            strategies: action.strategies,
            edit: state.components.createlessonplan.edit,
          },
        },
      };

    // remove
    case cases.REMOVE_STRATEGIES:
      return {
        ...state,
        components: {
          createlessonplan: {
            strategies: action.strategies,
            edit: state.components.createlessonplan.edit,
          },
        },
      };

    // update
    case cases.UPDATE_STRATEGIES:
      return {
        ...state,
        components: {
          createlessonplan: {
            strategies: action.strategies,
            edit: state.components.createlessonplan.edit,
          },
        },
      };
    default:
      return state;
  }
};

// components: {
//   createlessonplan: {
//     edit: { },
//     strategies: [],
// },
