import React from 'react';

import panelitems from './utils';

import { FooterContainer, BTPanelFooter } from './styled';

export default () => (
  <FooterContainer>
    <BTPanelFooter content={panelitems} />
  </FooterContainer>
);
