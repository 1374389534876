import styled from 'styled-components';

import I from '../../../base-components/autocomplete';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin-top: 24px;
`;

export const Input = styled(I)`
  font-size: 1rem;
  div {
    /* border-radius: 24px; */
  }
  label {
    margin-left: 4px;
  }
`;
