const initialState = {
    get: false,
    getview: false,
    post: false,
    put: false,
    del: false,
    lessonplan: [],
    view: [],
    report: [],
};

export default initialState;
