import React from 'react';
import { v4 as uuid } from 'uuid';
import ContainerAutcomplete from '../container-autocomplete';
import InputAutocomplete from '../input-autocomplete';
import datasubject from './data';
import filterSearch from '../../utils/filterSearch';
import TextField from '../text-input';

const ChipsSubjects = ({ label, subject, setSubject }) => (
  <ContainerAutcomplete id={uuid()}>
    <InputAutocomplete
      id={uuid()}
      // multiple
      options={datasubject}
      getOptionLabel={(option) => option.content}
      filterOptions={(option, state) => {
        if (state.inputValue.length > 2) {
          return option.filter((o) => filterSearch(state.inputValue, o));
        }
        return { content: 'vazio' };
      }}
      onChange={(event, newValue, reason) => {
        if (reason === 'select-option') {
          setSubject(newValue.content);
        }
      }}
      renderInput={(params) => (
        <TextField id={uuid()} {...params} label={label} />
      )}
      fullWidth
      value={{ content: subject }}
    />
  </ContainerAutcomplete>
);

export default ChipsSubjects;
