export const FLAG_LESSONPLAN_GET = 'FLAG_LESSONPLAN_GET';

export const FLAG_LESSONPLAN_GET_VIEW = 'FLAG_LESSONPLAN_GET_VIEW';

export const FLAG_LESSONPLAN_POST = 'FLAG_LESSONPLAN_POST';

export const FLAG_LESSONPLAN_PUT = 'FLAG_LESSONPLAN_PUT';

export const FLAG_LESSONPLAN_DEL = 'FLAG_LESSONPLAN_DEL';

export const FLAG_LESSONPLAN_REPORT = 'FLAG_LESSONPLAN_REPORT';

export const GET_LESSONPLAN = 'GET_LESSONPLAN';

export const GET_LESSONPLAN_VIEW = 'GET_LESSONPLAN_VIEW';

export const POST_LESSONPLAN = 'POST_LESSONPLAN';

export const POST_LESSONPLAN_PRINT = 'POST_LESSONPLAN_PRINT';

export const PUT_LESSONPLAN = 'PUT_LESSONPLAN';

export const DEL_LESSONPLAN = 'DEL_LESSONPLAN';

export const REPORT_LESSONPLAN = 'REPORT_LESSONPLAN';
