import React, { useState } from 'react';
import Modal from '../../../../components/modal';
import IconButton from '../../../../base-components/icon-button';
import Tooltip from '../../../../components/tooltip';
import Info from './info';
import { IconButtonInfoEvent } from './styled';
import InfoIcon from '../../../../base-components/info-icon';

export default ({ data }) => {
  const [handle, setHandle] = useState(false);

  const SetAction = () => (
    <Tooltip title="Informação" placement="right-end">
      <IconButton disabled aria-label="informação">
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );

  const body = <Info data={data} />;

  const trigger = <IconButtonInfoEvent onClick={() => setHandle(true)} />;

  return (
    <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
      {body}
    </Modal>
  );
};
