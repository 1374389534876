import * as lessonplan from './consts';

import initialState from './initialStates';

export default (state = initialState, action) => {
    switch (action.type) {
    case lessonplan.GET_LESSONPLAN:
        return { ...state, get: false, lessonplan: action.payload || [] };

    case lessonplan.GET_LESSONPLAN_VIEW:
        return {
            ...state,
            getview: false,
            // eslint-disable-next-line no-underscore-dangle
            [`${action.payload[0].uuid}`]: action.payload || [],
        };

    case lessonplan.POST_LESSONPLAN:
        return { ...state, post: false, lessonplan: action.payload || [] };

    case lessonplan.PUT_LESSONPLAN:
        return { ...state, put: false, lessonplan: action.payload || [] };

    case lessonplan.DEL_LESSONPLAN:
        return { ...state, del: false, lessonplan: action.payload || [] };

    case lessonplan.REPORT_LESSONPLAN:
        return { ...state, post: false, report: action.payload || [] };

    case lessonplan.FLAG_LESSONPLAN_GET:
        return { ...state, get: true };

    case lessonplan.FLAG_LESSONPLAN_GET_VIEW:
        return { ...state, getview: true };

    case lessonplan.FLAG_LESSONPLAN_POST:
        return { ...state, post: true };

    case lessonplan.FLAG_LESSONPLAN_PUT:
        return { ...state, put: true };

    case lessonplan.FLAG_LESSONPLAN_DEL:
        return { ...state, del: true };

    case lessonplan.FLAG_LESSONPLAN_REPORT:
        return { ...state, post: true };

    default:
        return state;
    }
};
