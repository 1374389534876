/**
 * input: input box text
 * o: Object for search includes input
 */

export default (input, o) => {
    let r = null;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Object.keys(o).length; i++) {
        const keyValue = o[`${Object.keys(o)[i]}`];
        if (
            keyValue.toString().indexOf(input) > -1 ||
            keyValue
                .toString()
                .indexOf(input.charAt(0).toUpperCase() + input.slice(1)) > -1 ||
            keyValue.toString().indexOf(input.toUpperCase()) > -1
        ) {
            r = o;
        }
    }
    return r;
};
