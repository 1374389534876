/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// eslint-disable-next-line import/prefer-default-export
export { default as wrapRootElement } from './wrap-with-provider';
