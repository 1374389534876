const initialState = {
    get: false,
    post: false,
    put: false,
    del: false,
    getSchool: false,
    postSchool: false,
    putSchool: false,
    delSchool: false,
    lessonplan: [],
    school: [],
    calendar: [],
    user: [],
    uuid: null,
};

export default initialState;
