export default {
    user: null,
    jwt: null,
    userData: null,
    islogged: false,
    resetstatus: false,
    reset: null,
    verifystatus: false,
    verify: null,
    error: false,
    msg: null,
};
