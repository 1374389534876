import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loadable from '@loadable/component';
import { setTabPlan } from '../../../state/reducers/app';
import Tabs from '../../base-components/tabs';
import Tab from '../../base-components/tab';
const TabPanel = Loadable(() => import('../components/tab-panel'));
const a11yProps = () => Loadable(() => import('../components/a11y-props'));
const Calendar = Loadable(() => import('./calendar'));
const MyLessonPlan = Loadable(() => import('./my-lessonplan'));
const CreateLessonPlan = Loadable(() => import('./create-lesson-plan'));
const MySchools = Loadable(() => import('./my-schools'));
const MyComments = Loadable(() => import('./my-comments'));

const Planning = () => {
  const dispatch = useDispatch();
  const tab = useSelector((state) => state.app.tabPlan);
  // const [value, dispatch(setTab] = React.useState(0);

  const handleChange = (event, newValue) => dispatch(setTabPlan(newValue));

  // TODO: Adicionar atividades no futuro usando ckeditor

  return (
    <div>
      <br />
      <div>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Calendário" {...a11yProps(0)} />
          <Tab label="Criar Plano de Aula" {...a11yProps(1)} />
          <Tab label="Meus Planos de Aula" {...a11yProps(2)} />
          {/* <Tab label="Atividades Planejadas" {...a11yProps(3)} /> */}
          <Tab label="Minhas Escolas" {...a11yProps(4)} />
          <Tab label="Meus Comentários" {...a11yProps(5)} />
        </Tabs>
      </div>
      <TabPanel value={tab} index={0}>
        <Calendar />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <CreateLessonPlan />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <MyLessonPlan />
      </TabPanel>
      {/* <TabPanel value={tab} index={3}>
          <Exercices />
        </TabPanel> */}
      <TabPanel value={tab} index={3}>
        <MySchools />
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <MyComments />
      </TabPanel>
    </div>
  );
}

export default Planning;