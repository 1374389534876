import axios from 'axios';

const SERVER_URL_API = 'https://api-v2-nodejs-vercel.vercel.app'; // "http://localhost:9001"; // https://apibajotech.herokuapp.com

const SERVER_URL_API_REPORT = 'https://api-v2-php-lessonplan-report.vercel.app';

export function apiGet(path, token) {
  return axios.request({
    method: 'get',
    url: `${SERVER_URL_API}${path}`,

    headers: {
      Authorization: 'Bearer '.concat(token),
    },
  });
}

export function apiPost(path, param, token) {
  return axios.request({
    method: 'post',
    url: `${SERVER_URL_API}${path}`,
    headers: {
      Authorization: 'Bearer '.concat(token),
    },
    data: param,
  });
}

export function apiDelete(path, token) {
  return axios.request({
    method: 'delete',
    url: `${SERVER_URL_API}${path}`,

    headers: {
      Authorization: 'Bearer '.concat(token),
    },
  });
}

export const apiUpdate = async (path, param, token) =>
  axios.request({
    method: 'put',
    url: `${SERVER_URL_API}${path}`,

    headers: {
      Authorization: 'Bearer '.concat(token),
    },
    data: param,
  });

export const apiReport = async (path, param, token) =>
  axios.request({
    method: 'post',
    url: `${SERVER_URL_API_REPORT}${path}`,

    headers: {
      Authorization: 'Bearer '.concat(token),
    },
    data: param,
  });
