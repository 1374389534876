import React from 'react';
import styled from 'styled-components';
import { Container } from '../styled';

const Paragraph = styled.p`
  text-indent: 1.25rem;
  text-align: justify;
  text-justify: inter-word;
`;

const Item = styled.li`
  text-align: justify;
  text-justify: inter-word;
`;

const org = (
  <a href="https://planodeaula.org" rel="noreferrer" target="_blank">
    planodeaula.org
  </a>
);

const email = (
  <a href="email:privacidade@planodeaula.org" rel="noreferrer" target="_blank">
    privacidade@planodeaula.org
  </a>
);

export default () => (
  <Container>
    <div>
      <section>
        <h3>Política de Privacidade</h3>
        <p>
          <b>Atualização:</b> <i> 19 de julho de 2021</i>
        </p>
      </section>
      <section>
        <Paragraph>
          Esta Política tem como objetivo demonstrar o nosso compromisso em
          resguardar a sua privacidade e proteger seus Dados Pessoais, além de
          descrever como os seus Dados Pessoais são coletados, usados,
          compartilhados e armazenados. Esta Política se aplica aos seguintes
          sites e domínios do {org}
        </Paragraph>
      </section>
      <section>
        <div>
          <p>
            <b>NOTA ESPECIAL PARA CRIANÇAS E ADOLESCENTES MENORES DE IDADE</b>
          </p>
          <Paragraph>
            Por favor, não se registre em nossas plataformas sem a autorização
            expressa dos seus pais ou responsáveis.
          </Paragraph>
        </div>
        <div>
          <p>
            <b>NOTA ESPECIAL PARA OS DADOS PESSOAIS</b>
          </p>
          <Paragraph>
            Nós podemos coletar os seguintes Dados Pessoais: Dados Pessoais que
            você nos fornece voluntariamente Nome completo, e-mail, coletamos
            automaticamente Endereço IP, geolocalização, informações sobre o
            dispositivo de acesso (p. ex. modelo, fabricante, sistema
            operacional), informações sobre o navegador de internet, duração da
            visita, páginas visitadas, conteúdos que você interagiu, cookies,
            etc.
          </Paragraph>
          <p>
            <b>Para que coletamos os Dados Pessoais</b>
          </p>
          <Paragraph>
            Nós utilizamos seus Dados Pessoais para diversas finalidades em
            nossos serviços:
          </Paragraph>
          <ul>
            <Item>
              Fazer o gerenciamento e processamento de seus planos de aula.
            </Item>
            <Item>
              Cumprir as obrigações decorrentes da prestação dos nossos
              serviços.
            </Item>
            <Item>
              Melhorar a sua experiência conosco e oferecer produtos, e serviços
              alinhados ao seu perfil, além de melhorar a apresentação de nossos
              sites.
            </Item>
            <Item>
              Enviar comunicação publicitária por e-mail, eventualmente.
            </Item>
            <Item>
              Veicular publicidade na Internet de forma personalizada ou não.
            </Item>
            <Item>
              Permitir a nossa comunicação com você, atender às suas
              solicitações, responder as suas dúvidas e reclamações.
            </Item>
            <Item>
              Processar sua candidatura a uma oportunidade de trabalho conosco.
            </Item>
            <Item>
              Implementar adequadas medidas adequadas de segurança para
              resguardar você e nossos sites.
            </Item>
            <Item>
              Corrigir eventuais problemas técnicos que você encontrar em nossos
              sites.
            </Item>
            <Item>
              Cumprir nossa obrigação legal de manter o registro do seu acesso
              aos sites.
            </Item>
          </ul>
        </div>
      </section>
      <section>
        <Paragraph>
          Muitos de nossos serviços dependem diretamente de alguns Dados
          Pessoais informados acima, principalmente Dados Pessoais relacionados
          a cadastro. Caso você opte por não fornecer alguns desses Dados
          Pessoais de cadastro, podemos ficar impossibilitados de prestar total
          ou parcialmente nossos serviços a você. Nós não somos obrigados a
          tratar quaisquer dos seus Dados Pessoais se houver razões para crer
          que tal tratamento possa nos imputar qualquer infração de qualquer lei
          aplicável, ou se você estiver utilizando nossos sites para quaisquer
          fins ilegais ou ilícitos. Nós utilizamos, em nossos sites, as
          seguintes tecnologias: Cookies, incluindo nossos prestadores de
          serviços terceiros, utilizamos determinadas ferramentas para coletar
          informações sobre você durante sua navegação nos sites. Essas
          ferramentas utilizam cookies, que são pequenos arquivos de texto
          armazenados no disco rígido do seu computador ou dispositivo. Os
          cookies são usados, entre outras coisas, para: melhorar o desempenho
          dos sites, fornecer opções de visualização personalizadas, compilar
          relatórios estatísticos sobre as atividades dos sites e para fins de
          segurança. Também utilizamos cookies para reconhecer você como um
          visitante anterior ou preencher formulários do site. Você sempre
          poderá restringir ou bloquear cookies. Os links abaixo contêm
          explicações a respeito da gestão de cookies, de acordo com o navegador
          utilizado:
        </Paragraph>
        <ul>
          <Item>
            <b>Internet Explorer: </b>
            <a
              href="http://windows.microsoft.com/pt-BR/internet-explorer/delete-manage-cookies#ie=ie-11-win-7"
              rel="noreferrer"
              target="_blank"
            >
              http://windows.microsoft.com/pt-BR/internet-explorer/delete-manage-cookies#ie=ie-11-win-7
            </a>
          </Item>
          <Item>
            <b>Mozilla Firefox: </b>
            <a
              href="https://support.mozilla.org/pt-PT/kb/Cookies%20-%20Portugu%C3%AAs"
              rel="noreferrer"
              target="_blank"
            >
              https://support.mozilla.org/pt-PT/kb/Cookies%20-%20Portugu%C3%AAs
            </a>
          </Item>
          <Item>
            <b>Google Chrome: </b>
            <a
              href="https://support.google.com/chrome/answer/95647?hl=pt"
              rel="noreferrer"
              target="_blank"
            >
              https://support.google.com/chrome/answer/95647?hl=pt
            </a>
          </Item>
          <Item>
            <b>Safari: </b>
            <a
              href="https://support.apple.com/kb/PH19255?locale=pt_BR"
              rel="noreferrer"
              target="_blank"
            >
              https://support.apple.com/kb/PH19255?locale=pt_BR
            </a>
          </Item>
          <Item>
            <b>Safari iOS (iPhone, iPad, iPod touch): </b>
            <a
              href="https://support.apple.com/pt-br/HT201265"
              rel="noreferrer"
              target="_blank"
            >
              https://support.apple.com/pt-br/HT201265
            </a>
          </Item>
        </ul>
        <Paragraph>
          Lembre-se que, ao desabilitar os cookies, você pode não ser capaz de
          tirar o máximo proveito de todos os recursos dos sites. Adwords,
          Google Analytics e Facebook, para registro de eventos durante
          navegação; Google Analytics, para registro de dados de navegação não
          identificáveis para análise.
        </Paragraph>
      </section>
      <section>
        <Paragraph>
          Todas as tecnologias utilizadas respeitarão sempre a legislação
          vigente e os termos desta Política. Como o {org} compartilha seus
          Dados Pessoais Nós podemos compartilhar seus Dados Pessoais com
          terceiros, sempre buscando preservar ao máximo a sua privacidade e,
          sempre que possível, de forma anonimizada. Abaixo, descrevemos
          situações em que podemos compartilhar os seus Dados Pessoais:
          Internamente, com nossas áreas de negócio, para realizar segmentação
          de perfil (oferecer produtos e serviços personalizados), enviar
          publicidade, promover e desenvolver nossos serviços, por exemplo.
        </Paragraph>
        <Paragraph>
          Com nossos fornecedores e parceiros comerciais, com quem firmamos
          obrigações contratuais de segurança e proteção de dados pessoais. Os
          fornecedores incluem empresas de hospedagem de dados e servidores;
          empresas de autenticação e validação de cadastros; ferramentas de
          publicidade, marketing, mídia digital e social; empresas de pesquisa.
        </Paragraph>
        <Paragraph>
          Com Autoridades Públicas, sempre que houver determinação legal,
          requerimento, requisição ou ordem judicial nesse sentido. De forma
          automática, em caso de movimentações societárias, como fusão,
          aquisição e incorporação do {org}.
        </Paragraph>
        <Paragraph>
          Para pesquisas de inteligência de mercado, divulgação de dados à
          imprensa e realização de propagandas, os Dados Pessoais serão
          compartilhados de forma anonimizada. Os Dados Pessoais podem ser
          transferidos para fora do Brasil. Essa transferência ocorre por conta
          de alguns dos nossos fornecedores, que podem estar localizados no
          exterior. Nesses casos, tomamos o cuidado de selecionar apenas
          empresas que demonstrem estar em conformidade com as leis aplicáveis.
        </Paragraph>
        <Paragraph>
          Quais são os seus direitos e como exercê-los A LGPD garante a você,
          como Titular dos Dados Pessoais, os seguintes direitos:
        </Paragraph>
        <ul>
          <Item>
            <b>Acesso:</b> Você requisitar uma cópia dos Dados Pessoais que nós
            temos sobre você.
          </Item>
          <Item>
            <b>Anonimização, bloqueio ou eliminação:</b> Você pode solicitar a
            anonimização de seus Dados Pessoais; o bloqueio de seus Dados
            Pessoais, suspendendo temporariamente o Tratamento e eliminação de
            seus Dados Pessoais, caso em que apagaremos todos os seus Dados
            Pessoais, salvo os casos em que a lei exigir sua manutenção.
          </Item>
          <Item>
            <b>Confirmação de Tratamento:</b> Você pode verificar se o {org}{' '}
            trata algum Dado Pessoal seu.
          </Item>
          <Item>
            <b>Correção: </b>Você pode corrigir os Dados Pessoais que estejam
            incompletos, inexatos ou desatualizados.
          </Item>
          <Item>
            <b>Informações sobre o compartilhamento:</b> Você tem o direito de
            saber com quais entidades públicas e privadas o {org} compartilha os
            seus Dados Pessoais.
          </Item>
          <Item>
            <b>Oposição:</b> Você pode se opor às atividades de Tratamento que
            não dependam do consentimento. Caso você não concorde com o referido
            Tratamento, você poderá solicitar sua interrupção.
          </Item>
          <Item>
            <b>Portabilidade:</b> É o direito de solicitar que o {org} forneça a
            você, ou terceiro à sua escolha, seus Dados Pessoais para
            transferência a outro fornecedor do mesmo ramo de atuação que o{' '}
            {org}.
          </Item>
          <Item>
            <b>
              Revogação do consentimento e informação sobre suas consequências:{' '}
            </b>
            Você tem o direito de não dar ou retirar seu consentimento e obter
            informações sobre as consequências dessa escolha. Nesse caso, alguns
            de nossos serviços poderão ficar indisponíveis para você. Você
            poderá entrar em contato com nosso Encarregado pelo e-mail {email}{' '}
            para exercer os direitos acima descritos.
          </Item>
        </ul>
        <p>
          <b>Como armazenamos seus Dados Pessoais</b>
        </p>
        <Paragraph>
          Os Dados Pessoais são armazenados somente pelo tempo que for
          necessário para cumprir com as finalidades para as quais foram
          coletados, salvo se houver outra razão para sua manutenção como o
          cumprimento de obrigações legais, regulatórias, contratuais e
          preservação de direitos do {org}, desde que devidamente fundamentadas.
        </Paragraph>
        <Paragraph>
          Caso você solicite a exclusão de seus Dados Pessoais, pode ocorrer que
          eles precisem ser mantidos por período superior ao pedido de exclusão,
          nos termos do artigo 16 da LGPD. Terminado o prazo de manutenção e
          necessidade legal, os Dados Pessoais serão excluídos com uso de
          métodos de descarte seguro, ou utilizados de forma anonimizada para
          fins estatísticos.
        </Paragraph>
        <Paragraph>
          Confidencialidade e segurança dos Dados Pessoais Internamente, os seus
          Dados Pessoais são acessados somente por profissionais devidamente
          autorizados, respeitando os princípios de proporcionalidade,
          necessidade e relevância. Além disso, nós utilizamos medidas
          tecnológicas de segurança e nossos colaboradores passam por
          treinamentos periódicos sobre segurança da informação.
        </Paragraph>
        <Paragraph>
          Para que seus Dados Pessoais permaneçam seguros, aconselhamos que você
          não compartilhe suas senhas com terceiros, mesmo que sejam amigos ou
          parentes, além de se certificar de sempre clicar em “sair” ao encerrar
          sua navegação em um computador compartilhado. O {org} nunca envia
          e-mails pedindo seus Dados Pessoais, nem solicitando downloads ou
          execução de ações (abertura de arquivos com extensão .exe, .com, .src,
          .bat, entre outras).
        </Paragraph>
        <p>
          <b>Sites de terceiros</b>
        </p>
        <Paragraph>
          Nós recomendamos que você leia as Políticas de Privacidade de tais
          sites, sendo de sua responsabilidade aceitá-las ou rejeitá-las. Nós
          não somos responsáveis pelas Políticas de Privacidade de terceiros e
          nem pelo conteúdo de quaisquer sites ou serviços que não os nossos.
        </Paragraph>
      </section>
      <section>
        <p>
          <b>Atualização e Contato</b>
        </p>
        <Paragraph>
          Nós temos o direito de alterar esta Política a qualquer momento,
          conforme a finalidade ou necessidade, cabendo a você verificá-la
          sempre que acessar nossos sites ou utilizar nossos serviços. Nós nunca
          iremos fazer alterações significativas nesta Política sem te avisar
          antes! Em caso de solicitações, reclamações, dúvidas ou sugestões em
          relação a nossa Política, por favor entre em contato com nosso
          Encarregado: {email} Essa Política será interpretada segundo a
          legislação brasileira, no idioma português, sendo eleito o foro do seu
          domicílio para dirimir qualquer controvérsia que envolva este
          documento, salvo ressalva específica de competência pessoal,
          territorial ou funcional pela legislação aplicável.
        </Paragraph>
        <Paragraph>
          Caso você não possua domicílio no Brasil, em razão dos serviços
          oferecidos pelo {org}, se submete à legislação brasileira,
          concordando, portanto, que em havendo litígio a ser solucionado.
        </Paragraph>
        <Paragraph>
          <b>Glossário Anonimização: </b> Utilização de meios técnicos razoáveis
          e disponíveis no momento do Tratamento, por meio dos quais um dado
          perde a possibilidade de associação, direta ou indireta, a um
          indivíduo.
        </Paragraph>
        <Paragraph>
          <b>Cookies: </b> Pequenos arquivos enviados pelos websites, salvos nos
          seus dispositivos, que armazenam as preferências e poucas outras
          informações, com a finalidade de personalizar sua navegação de acordo
          com o seu perfil.
        </Paragraph>
        <Paragraph>
          <b>Dado Pessoal: </b> É toda informação relacionada a uma pessoa
          natural identificada ou identificável.
        </Paragraph>
        <Paragraph>
          <b>Encarregado: </b> Pessoa indicada por nós para atuar como canal de
          comunicação entre o {org}, você e a Autoridade Nacional de Proteção de
          Dados (ANPD).
        </Paragraph>
        <Paragraph>
          <b>Endereço IP: </b>
          Abreviatura de Internet Protocol. É o conjunto alfanumérico que
          identifica os dispositivos dos usuários na Internet.
        </Paragraph>
        <Paragraph>
          <b>LGPD: </b> Lei Geral de Proteção de Dados Pessoais – Lei nº.
          13.709/18,
          (http://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/L13709compilado.htm)
          que dispõe sobre o tratamento de dados pessoais em meios digitais ou
          não.
        </Paragraph>
        <Paragraph>
          <b>Sites: </b> São os sites e domínios de titularidade do {org},
          indicados no começo desta Política.
        </Paragraph>
        <Paragraph>
          <b>Titular dos Dados Pessoais: </b> É você, a pessoa física a quem os
          Dados Pessoais se referem.
        </Paragraph>
        <Paragraph>
          <b>Tratamento: </b>
          Toda operação realizada com Dados Pessoais, como a coleta, produção,
          recepção, classificação, utilização, acesso, reprodução, transmissão,
          distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação ou controle da informação, modificação, comunicação,
          transferência, difusão ou extração.
        </Paragraph>
      </section>
    </div>
  </Container>
);
