import Loadable from '../loadable'
import styled from 'styled-components';
const TextField = Loadable(() => import('@mui/material/TextField'));

export default styled(TextField)`
label {
    font-size: 0.75rem;
    border-radius: 16px;
  }
  input {
    font-size: 0.75rem;
    border-radius: 16px;
  }
  border-radius: 16px;
`;