export const FLAG_COMMENTS_GET = 'FLAG_COMMENTS_GET';

export const FLAG_COMMENTS_GET_VIEW = 'FLAG_COMMENTS_GET_VIEW';

export const FLAG_COMMENTS_POST = 'FLAG_COMMENTS_POST';

export const FLAG_COMMENTS_PUT = 'FLAG_COMMENTS_PUT';

export const FLAG_COMMENTS_DEL = 'FLAG_COMMENTS_DEL';

export const GET_COMMENTS = 'GET_COMMENTS';

export const GET_COMMENTS_VIEW = 'GET_COMMENTS_VIEW';

export const POST_COMMENTS = 'POST_COMMENTS';

export const POST_COMMENTS_PRINT = 'POST_COMMENTS_PRINT';

export const PUT_COMMENTS = 'PUT_COMMENTS';

export const DEL_COMMENTS = 'DEL_COMMENTS';
