import React from 'react';
import styled from 'styled-components';
import { Container } from '../styled';

const Paragraph = styled.p`
  text-indent: 1.25rem;
  text-align: justify;
  text-justify: inter-word;
`;

// const Item = styled.li`
//   text-align: justify;
//   text-justify: inter-word;
// `;

// const org = (
//   <a href="https://planodeaula.org" rel="noreferrer" target="_blank">
//     planodeaula.org
//   </a>
// );

export default () => (
  <Container>
    <div>
      <section>
        <h3>Quem Somos?</h3>
      </section>
      <section>
        <Paragraph>
          Somos um grupo de professores, empenhados a difundir as boas práticas.
          Contribuindo e compartilhando conhecimento. Buscando ferramentas para
          obter formas de ensino com excelência.
        </Paragraph>
      </section>
    </div>
  </Container>
);
