import React from 'react';
import { v4 as uuid } from 'uuid';
import Strategies from '..';
import EditStrategies from '../edit-strategies';

const RenderStrategies = ({ strategies, setStrategies, edit, setEdit }) =>
  strategies.map((s) => (
    <div key={uuid()}>
      {s.step === edit?.step ? (
        <EditStrategies
          id={uuid()}
          strategies={strategies}
          edit={edit}
          setEdit={setEdit}
        />
      ) : (
        <Strategies
          strategies={strategies}
          s={s}
          id={uuid()}
          setEdit={setEdit}
          setStrategies={setStrategies}
        />
      )}
    </div>
  ));

export default RenderStrategies;
