import React from 'react';
import Loadable from '@loadable/component';
import LazyLoading from '../components/lazy-loading';

export const Home = Loadable(() => import('./home'), {
  fallback: <LazyLoading />,
});

export const Terms = Loadable(() => import('./terms'), {
  fallback: <LazyLoading />,
});

export const Privacity = Loadable(() => import('./privacity'), {
  fallback: <LazyLoading />,
});

export const About = Loadable(() => import('./about'), {
  fallback: <LazyLoading />,
});

export const Ads = Loadable(() => import('./ads'), {
  fallback: <LazyLoading />,
});
