import * as defines from './consts';

export const signIn = (payload) => ({
    type: 'API_METHOD_POST_LOGIN',
    subtype: defines.SIGNIN,
    path: '/v2/auth/signin',
    payload,
});

export const logOut = () => ({
    type: defines.LOGOUT,
});
