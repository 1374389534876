import React from 'react';
import { v4 as uuid } from 'uuid';
import TextField from '../text-input';

export default ({ time, setTime }) => (
  <TextField
    id={uuid()}
    label="Horário"
    type="time"
    defaultValue={time}
    InputLabelProps={{
      shrink: true,
    }}
    inputProps={{
      step: 300, // 5 min
    }}
    onChange={(e) => setTime(e.target.value)}
  />
);
