import React from 'react';
import Loadable from '@loadable/component';
import { IconButtonMenuEvent, } from './styled';
import LinkNav from '../../../../components/linknav';
import IconButtonPdfEvent from '../../../../components/icon-button-pdf-event';
import Menu from '../../../../base-components/menu';
import MenuItem from '../../../../base-components/menu-item';

const ModalDeleteEvent = Loadable(() => import('./modal-delete-event'));
const ModalEditEvent = Loadable(() => import('./modal-edit-event'));
const ModalInfoEvent = Loadable(() => import('./modal-info-event'));

export default function MenuEventContent({ data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const header = {
    url: data.event.extendedProps.schoolschedule[0].url,
    name: data.event.extendedProps.schoolschedule[0].name,
    dir: data.event.extendedProps.schoolschedule[0].dir,
    coord: data.event.extendedProps.schoolschedule[0].coord,
    prof: data.event.extendedProps.schoolschedule[0].prof,
    date: { start: data.event.startStr, end: data.event.endStr },
    time: data.event.extendedProps.schoolschedule[0].time,
  };

  return (
    <div>
      <IconButtonMenuEvent
        aria-controls="menu-calendar-event"
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="menu-calendar-event"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <ModalDeleteEvent uuid={data.event.extendedProps.uuid} />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ModalEditEvent data={data} />
        </MenuItem>
        {data.event.title && (
          <MenuItem onClick={handleClose}>
            <LinkNav
              style={{ marginLeft: '0px' }}
              to={`/app/lessonplan/${data.event.extendedProps.plan
                }/report/${btoa(JSON.stringify(header))}`}
              text={<IconButtonPdfEvent aria-label="visualizar" />}
            />
          </MenuItem>
        )}
        {data.event.title && (
          <MenuItem>
            <ModalInfoEvent data={data} />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
