import * as actions from './consts';

export const viewCalendarEvents = () => ({
  type: 'API_METHOD_GET',
  subtype: actions.VIEW_CALENDAR_EVENTS,
  flag: actions.FLAG_CALENDAR_EVENTS_GET,
  path: '/calendar/event',
});

export const viewCalendarEventsByRange = ({ start, end }) => ({
  type: 'API_METHOD_GET',
  subtype: actions.VIEW_CALENDAR_EVENTS,
  flag: actions.FLAG_CALENDAR_EVENTS_GET,
  path: `/calendar/event/byrange?start=${start}&end=${end}`,
});

export const viewCalendarEvent = (uuid) => ({
  type: 'API_METHOD_GET',
  subtype: actions.VIEW_CALENDAR_EVENT,
  flag: actions.FLAG_CALENDAR_EVENTS_GET,
  path: `/calendar/event/${uuid}/view`,
});

export const insertCalendarEvents = (payload) => ({
  type: 'API_METHOD_POST',
  subtype: actions.INSERT_CALENDAR_EVENTS,
  flag: actions.FLAG_CALENDAR_EVENTS_POST,
  state: 'calendar',
  intercept: 'calendarevents',
  path: '/calendar/event/insert',
  payload,
});

export const modifyCalendarEvents = (uuid, payload) => ({
  type: 'API_METHOD_PUT',
  subtype: actions.MODIFY_CALENDAR_EVENTS,
  flag: actions.FLAG_CALENDAR_EVENTS_PUT,
  state: 'calendar',
  intercept: 'calendarevents',
  path: `/calendar/event/${uuid}/update`,
  payload,
  uuid,
});

export const removeCalendarEvents = (uuid) => ({
  type: 'API_METHOD_DELETE',
  subtype: actions.REMOVE_CALENDAR_EVENTS,
  flag: actions.FLAG_CALENDAR_EVENTS_DELETE,
  state: 'calendar',
  intercept: 'calendarevents',
  path: `/calendar/event/${uuid}/delete`,
  uuid,
});

export const setDate = (payload) => ({
  type: 'SET_DATE',
  payload,
});
