import React from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { getLessonplanView } from '../../../state/reducers/lessonplan';
import ViewLessonplan from '../components/view-lesson-plan';
import Card from '../../components/card';
import Container from '../../components/container';
import Typography from '../../components/typography';
import CardHeader from '../../base-components/card-header'

export default ({ id }) => {
  const dispatch = useDispatch();

  React.useMemo(() => {
    dispatch(getLessonplanView(id));
  }, [dispatch]);

  const view = useSelector((state) => state?.lessonplan[`${id}`]);

  console.log(view);
  return view ? (
    <Container>
      <Card>
        <CardHeader
          title={<Typography paragraph>{view[0].title}</Typography>}
          subheader={
            <Typography>{`${view[0].class}, ${view[0].subject} `}</Typography>
          }
        />
        <div style={{ padding: '16px' }}>
          <ViewLessonplan key={uuid()} view={view[0]} />
        </div>
        <br />
      </Card>
    </Container>
  ) : (
    <p>Loading...</p>
  );
};
