/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import { call, put, select } from 'redux-saga/effects';
import * as api from './api';

import * as Utils from './utils';

export default function* postEffectSaga(action) {
    try {
        yield put({ type: action.flag }); // set status false

        const state = yield select();

        const { jwt } = state.auth;
        // get data api from axios post
        const { data } = yield call(
            api.apiPost,
            action.path,
            action.payload,
            jwt,
        );

        if (data.code === 1) {
            if (action.intercept) {
                const old = state[action?.state][action?.intercept];

                const result = Utils.insert(old, action?.payload);

                if (result) {
                    yield put({
                        type: action.subtype,
                        code: data.code,
                        msg: data.message,
                        payload: Array.from(result),
                    });
                }
            }
            return alert(data.message);
        }
        if (data.code === 0) {
            return Utils.errorMensageApi(data);
        }
    } catch (e) {
        alert(e);
    }
}
