/* eslint-disable func-names */
import React from 'react';
import styled from 'styled-components';
import { Container } from '../styled';

const Paragraph = styled.p`
  text-indent: 1.25rem;
  text-align: justify;
  text-justify: inter-word;
`;

const Item = styled.li`
  text-align: justify;
  text-justify: inter-word;
`;

const org = (
  <a href="https://planodeaula.org" rel="noreferrer" target="_blank">
    planodeaula.org
  </a>
);

export default function () {
  return (
    <Container>
      <div>
        <section>
          <h3>Termos e Condições de Uso</h3>
          <p>
            <b>Atualização:</b> <i> 19 de julho de 2021</i>
          </p>
        </section>
        <section>
          <Paragraph>
            A seguir apresentamos os Termos e Condições de Uso (“Termos de
            Uso”), documento que relaciona as principais regras que devem ser
            observadas por todos que acessam os sites {org}.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Ao acessar ou utilizar as funcionalidades dos nossos sites, você
            declara que fez a leitura completa e atenta das regras aqui
            constantes, estando plenamente ciente e de acordo com os Termos de
            Uso. Se você não concorda com os Termos de Uso, por favor, não
            acesse nem utilize nossos sites. Os sites foram criados para
            apresentar informações ao público em geral e permitir o contato com
            o {org}, buscando tornar o contato o mais acessível possível.
          </Paragraph>
          <Paragraph>
            Os sites e suas funcionalidades são apresentados a você na maneira
            como estão disponíveis, podendo passar por constantes aprimoramentos
            e atualizações Uso dos sites Você poderá usar os sites apenas para
            finalidades lícitas, não sendo permitido publicar, enviar,
            distribuir ou divulgar conteúdos ou informação de caráter
            difamatório, obsceno ou ilícito, inclusive informações de
            propriedade exclusiva pertencentes a outras pessoas ou empresas, bem
            como marcas registradas ou informações protegidas por direitos
            autorais, sem a expressa autorização do detentor desses direitos.
          </Paragraph>
          <Paragraph>
            Você também se compromete a não acessar áreas de programação dos
            sites, seus bancos de dados, códigos fonte ou qualquer outro
            conjunto de dados disponíveis nestes ambientes, bem como não
            realizar ou permitir engenharia reversa, nem traduzir, decompilar,
            copiar, modificar, reproduzir, alugar, sublicenciar, publicar,
            divulgar, transmitir, emprestar, distribuir ou, de outra maneira,
            dispor inapropriadamente das funcionalidades dos sites. Ainda, você
            não poderá usar softwares de mineração de dados (bots, crawler,
            spider, etc.), de qualquer tipo ou espécie nos sites para obter ou
            divulgar informações, inclusive dados pessoais e endereços na
            Internet, sobre o{org} ou outros usuários dos sites.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Nós nos empenhamos em manter a qualidade, atualidade e autenticidade
            das informações do site, mas seus criadores e colaboradores não se
            responsabilizam por eventuais falhas nos serviços ou inexatidão das
            informações oferecidas. O usuário não deve ter como pressuposto que
            tais serviços e informações são isentos de erros ou serão adequados
            aos seus objetivos particulares. Os criadores e colaboradores
            tampouco assumem o compromisso de atualizar as informações e
            reservam-se o direito de alterar as condições de uso ou preços dos
            serviços e produtos oferecidos no site a qualquer momento. O acesso
            ao site {org}é gratuito.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            O {org} poderá criar áreas de acesso exclusivo aos seus clientes ou
            para terceiros especialmente autorizados. Os criadores e
            colaboradores do {org} poderão, a seu exclusivo critério e em
            qualquer tempo, modificar ou desativar o site, bem como limitar,
            cancelar ou suspender seu uso ou o acesso.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Também estes Termos de Uso poderão ser alterados a qualquer tempo.
            Visite regularmente esta página e consulte os Termos então vigentes.
            Algumas disposições destes Termos podem ser substituídas por termos
            ou avisos legais expressos localizados em determinadas páginas deste
            site.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Limitação da responsabilidade Os documentos, informações, imagens e
            gráficos publicados nos sites podem conter imprecisões técnicas ou
            erros tipográficos. O acesso aos serviços, materiais, informações e
            facilidades contidas nos sites não garante a sua qualidade. Os
            materiais são fornecidos nos sites sem nenhuma garantia explícita ou
            implícita de comercialização ou adequação a qualquer objetivo
            específico.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Em nenhum caso será responsabilizado por quaisquer danos, incluindo
            lucros cessantes, interrupção de negócio ou perda de informação que
            resultem da impossibilidade de uso e/ou acesso dos serviços
            oferecidos, das informações disponíveis nos sites e/ou do uso ou da
            sua incapacidade de usar os materiais fornecidos. Sem prejuízo de
            outras disposições dos Termos de Uso, o {org} não se responsabiliza:
          </Paragraph>
          <ul>
            <Item>
              <b>i.</b> Pelo uso indevido das informações, dos instrumentos e/ou
              dos materiais disponibilizados por este site, para quaisquer que
              sejam os fins, feito por você, sendo de sua inteira
              responsabilidade as eventuais lesões a direito próprio ou de
              terceiros, causadas ou não por esse uso inadequado;
            </Item>
            <Item>
              <b>ii.</b> Pelo conteúdo de qualquer uma das informações trocadas
              entre os usuários dos sites, sejam elas lícitas ou ilícitas;
            </Item>
            <Item>
              <b>iii.</b> Por quaisquer problemas, bugs, glitches ou
              funcionamentos indevidos que ocorrerem nos seus dispositivos e
              equipamentos;
            </Item>
            <Item>
              <b>iv.</b> Por qualquer dano direto ou indireto ocasionado por
              eventos de terceiros, a exemplo, mas não se limitando a ataque de
              hackers, falhas no sistema, no servidor ou na conexão à internet,
              inclusive por ações de softwares que possam, de algum modo,
              danificar seus ativos físicos ou lógicos em decorrência do acesso,
              utilização ou navegação no site, bem como a transferência de
              dados, arquivos, imagens, textos, áudios ou vídeos contidos no
              site;
            </Item>
            <Item>
              <b>v.</b> Pela sua navegação em quaisquer links externos
              disponibilizados nos sites, sendo seus deveres a leitura dos
              Termos de Uso e da Política de Privacidade do recurso acessado;
            </Item>
            <Item>
              <b>vi.</b> Pelo Pela violação de direitos autorais decorrente de
              informações, documentos e materiais publicados por terceiros nos
              sites, comprometendo-se a retirá-los do ar assim que notificado da
              infração.
            </Item>
          </ul>
        </section>
        <section>
          <Paragraph>
            Informações enviadas pelos usuários e colaboradores É
            terminantemente proibido transmitir, trocar ou publicar, através dos
            sites, qualquer material de cunho obsceno, difamatório ou ilegal,
            bem como textos ou criações de terceiros sem a autorização do autor.
            Nós nos reservamos o direito de restringir o acesso às informações
            enviadas por terceiros aos usuários. Nós poderemos, mas não somos
            obrigados, monitorar, revistar e restringir o acesso a qualquer área
            nos sites onde usuários transmitem e trocam informações entre si,
            incluindo, mas não limitado a, salas de chat, centro de mensagens ou
            outros fóruns de debates, podendo retirar do ar ou retirar o acesso
            a qualquer destas informações ou comunicações.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Nós não nos responsabilizamos pelo conteúdo de qualquer das
            mensagens e/ou informações trocadas entre os usuários, sejam elas
            lícitas ou ilícitas. Direitos autorais e propriedade intelectual Os
            documentos, conteúdos e criações contidos nos sites pertencem ao{' '}
            {org}. A autoria dos conteúdos, materiais e imagens exibidos nos
            sites é protegida pela legislação nacional e internacional de
            Propriedade Intelectual. Não podem ser copiados, reproduzidos,
            modificados, publicados, atualizados, postados, transmitidos ou
            distribuídos de qualquer maneira sem autorização prévia e por
            escrito do {org}.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            As imagens contidas nos sites são incorporadas apenas para fins de
            visualização, e, salvo autorização expressa por escrito, não podem
            ser gravadas ou baixadas via download. A reprodução ou armazenamento
            de materiais recuperados a partir deste serviço sujeitará os
            infratores às penas da lei.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Os nomes de domínios dos sites (por exemplo {org} ), seus logotipos,
            bem como todos os elementos característicos da tecnologia
            desenvolvida e aqui apresentada, sob a forma da articulação de bases
            de dados, constituem marcas registradas e propriedades intelectuais
            privadas e todos os direitos decorrentes de seu registro são
            assegurados por lei. Alguns direitos de uso podem ser cedidos por
            nós em contrato ou licença especial, que pode ser cancelada a
            qualquer momento se não cumpridos os seus termos.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            As marcas registradas do {org} só podem ser usadas publicamente com
            autorização expressa. O uso destas marcas registradas em publicidade
            e promoção de produtos deve ser adequadamente informado. Reclamações
            sobre violação de direitos autorais Nós respeitamos a propriedade
            intelectual de outras pessoas ou empresas e solicitamos aos nossos
            colaboradores, alunos e parceiros que façam o mesmo. Toda e qualquer
            violação de direitos autorais deverá ser notificada ao {org} e
            acompanhada dos documentos e informações que confirmam a autoria.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            A notificação poderá ser enviada pelos e-mails constantes do site.
            Links para sites de terceiros Nós não somos responsáveis pelo
            conteúdo de qualquer site de terceiros indicado ou acessado por meio
            dos nossos ambientes. Nós nos reservamos o direito de eliminar
            qualquer link ou direcionamento a outros sites ou serviços a
            qualquer momento. Nós não endossamos firmas ou produtos indicados,
            acessados ou divulgados através dos sites, tampouco pelos anúncios
            ali publicados, reservando o direito de publicar este alerta em
            nossas páginas eletrônicas sempre que considerarmos necessário.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Disposições Gerais Os Termos de Uso estão sujeitos a constante
            melhoria e aprimoramento. Assim, o {org} se reserva o direito de
            modificá-los a qualquer momento, conforme sua finalidade ou
            conveniência, cabendo a você verificá-lo sempre que efetuar o acesso
            no site A tolerância do eventual descumprimento de quaisquer
            condições destes Termos de Uso não constituirá renúncia ou novação
            nem impedirá o {org} de exigir estas condições a qualquer tempo.
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Se alguma disposição dos Termos de Uso for julgada inaplicável ou
            sem efeito, o restante continuará a viger normalmente. O site tem
            como base a data e horários oficiais de Brasília (UTC/GMT -03:00).
          </Paragraph>
        </section>
        <section>
          <Paragraph>
            Aqueles que optarem por acessar este site a partir de outras
            localidades o farão por iniciativa própria e serão responsáveis pelo
            cumprimento das leis locais aplicáveis. Os materiais não deverão ser
            usados ou exportados em descumprimento das leis brasileiras sobre
            exportação.
          </Paragraph>
          <Paragraph>
            Qualquer pendência com relação aos materiais será dirimida pelas
            leis brasileiras. Os Termos de Uso serão interpretados segundo a
            legislação brasileira, no idioma português, salvo se a legislação
            aplicável ressalvar especificamente outra competência pessoal,
            territorial ou funcional.
          </Paragraph>
        </section>
      </div>
    </Container>
  );
}
