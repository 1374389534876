import React from "react";
import { v4 as uuid } from 'uuid';
import InputChipsResources from '../../../../../components/autocomplete-input-chips-resources';

const FalecaTurmaextFieldStrategiesFase = ({ setEdtDialogue, edtdialogue }) => {
    return (
        <InputChipsResources
            id={uuid()}
            label="Fale com a turma"
            resources={edtdialogue}
            setResources={setEdtDialogue}
        />
    )
}

export default FalecaTurmaextFieldStrategiesFase;