import React from "react";
import UpdateStrategies from '../../components/update-strategies-fase';
import CancelStrategiesFase from '../../components/cancel-strategies-fase';
import BarDotted from "../../../../../components/bar-dotted";

const BarStrategiesEditTools = (
    {
        setEdit,
        strategies,
        edit,
        edttime,
        edtguidance,
        edtpurpose,
        edtdialogue,
        edtresources
    }) => {
    return (
        <BarDotted>
            {/* update button */}
            <UpdateStrategies
                strategies={strategies}
                setEdit={setEdit}
                edit={edit}
                edttime={edttime}
                edtguidance={edtguidance}
                edtpurpose={edtpurpose}
                edtdialogue={edtdialogue}
                edtresources={edtresources}
            />
            {/* cancel button */}
            <CancelStrategiesFase setEdit={setEdit} />
        </BarDotted>
    )
}

export default BarStrategiesEditTools;