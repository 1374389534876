import React from 'react';
import styled, { css } from 'styled-components';
import Loadable from '@loadable/component';
import { v4 as uuid } from 'uuid';
import { Link } from 'gatsby';
import device from '../../utils/device';
import { neutral, primaryDark } from '../colors';
import IconButton from '../../base-components/icon-button';
const FacebookIcon = Loadable(() => import('@material-ui/icons/Facebook'));
const GitHubIcon = Loadable(() => import('@material-ui/icons/GitHub'));
const InstagramIcon = Loadable(() => import('@material-ui/icons/Instagram'));
const YouTubeIcon = Loadable(() => import('@material-ui/icons/YouTube'));

// import backmenu from '../../images/backmenu.svg';

const center = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const FooterContainer = styled(({ children }) => <div>{children}</div>)`
  display: flex;
  width: 100%;
  /* min-height: calc(60vh - 62px); */
`;

/**
 *  BTPanelFooter
 *
 */

const PanelFooter = styled.div`
  width: 100%;
  min-height: calc(60vh - 62px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${primaryDark};
  @media ${device.tablet} {
    ${center}
    flex-direction: column;
  }
`;

const Item = styled(Link)`
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  margin-left: 18px;
  color: ${neutral};
  :link,
  :visited {
    text-decoration: none;
  }
  :hover {
    // font-size: 1rem;
    color: #028090;
    text-decoration-style: wavy;
    text-decoration-color: darkturquoise;
  }
  @media ${device.tablet} {
    ${center}
    margin-left: 0px;
    margin-bottom: 24px;
  }
  text-decoration: none;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  & > button {
    padding: 16px;
  }
`;

const iconCss = css`
  font-size: 24px;
  color: ${neutral};
  :hover {
    // font-size: 1rem;
    color: #028090;
  }
`;

const BTFacebookOutlined = styled(FacebookIcon)`
  ${iconCss}
`;

const BTGithubOutlined = styled(GitHubIcon)`
  ${iconCss}
`;

const BTInstagramOutlined = styled(InstagramIcon)`
  ${iconCss}
`;

const BTYoutubeOutlined = styled(YouTubeIcon)`
  ${iconCss}
`;

export const BTPanelFooter = ({ content }) => (
  <PanelFooter>
    {content.map((o) => (
      <Item key={uuid()} to={o.route}>
        {o.name}
      </Item>
    ))}
    <IconContainer>
      <IconButton aria-label="Facebook">
        <BTFacebookOutlined />
      </IconButton>
      <IconButton aria-label="Github">
        <BTGithubOutlined />
      </IconButton>
      <IconButton aria-label="Instagram">
        <BTInstagramOutlined />
      </IconButton>
      <IconButton aria-label="Youtube">
        <BTYoutubeOutlined />
      </IconButton>
    </IconContainer>
  </PanelFooter>
);

export const BTPanelCopyright = styled.div`
  width: 100%;
  max-height: 80px;
  display: flex;
  align-items: center;

  box-sizing: border-box;
  border: 1px solid ${primaryDark};
`;

export const BTCopyright = styled.p`
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  text-align: center;
  color: ${primaryDark};
`;
