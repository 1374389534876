const update = (s, e) => {
  let it = [];
  if (s?.length > 0) {
    it = s.map((o) => {
      if (o.step === e.step) {
        const n = Object.assign(o, e);
        return n;
      }
      return o;
    });

    return it;
  }

  return s;
};

export default update;
