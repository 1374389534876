import React from 'react';
import { v4 as uuidv4 } from 'uuid'
import GoogleIcon from '@mui/icons-material/Google';
import { useDispatch } from 'react-redux';

import GoogleLogin from 'react-google-login';
import Button from './styled';

import { signIn, logOut } from '../../../state/reducers/auth';

export default ({ isLogged }) => {
  const dispatch = useDispatch();

  const responseGoogle = (response) => {
    dispatch(signIn(response));
  };

  return isLogged ? (
    <Button id={uuidv4()} onClick={() => dispatch(logOut())}>Sair</Button>
  ) : (
    <GoogleLogin
      id={uuidv4()}
      clientId="940031482142-ov9f1rcav4g0ttjefpae9e30va2krt8g.apps.googleusercontent.com"
      render={(renderProps) => (
        <Button
          id={uuidv4()}
          onClick={renderProps.onClick}
          startIcon={<GoogleIcon />}
          disabled={renderProps.disabled}>
          Sign in
        </Button>
      )}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy="single_host_origin"
      scope={('profile', 'email')}
      accessType="offline"
    />
  );
};
