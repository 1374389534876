import React from "react";
import { v4 as uuid } from 'uuid';
import TextField from '../../../../../components/text-input';

const ObjetivoTextFieldLessonplan = ({ setHub, hub }) => {
    return (
        <TextField
            id={uuid()}
            label="Objetivo"
            value={hub}
            onChange={(e) => setHub(e.target.value)}
        />
    )
}

export default ObjetivoTextFieldLessonplan;