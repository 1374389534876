import React from 'react';
import { useDispatch } from 'react-redux';
import { putLessonplan } from '../../../../../../state/reducers/lessonplan';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import SaveTwoToneIcon from '../../../../../base-components/save-two-tone-icon';
import AddIcon from '../../../../../base-components/add-icon';

const ButtonActions = ({ strategies, setStrategies, put, uuid }) => {
  const dispatch = useDispatch();
  const insert = () => {
    const nova = {
      step: strategies.length + 1,
      time: `1 minuto(s).`,
      guidance: '',
      purpose: '',
      dialogue: [],
      resources: [],
    };

    return [...strategies, nova];
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        border: '0.2px solid',
        borderStyle: 'dotted',
        borderRadius: '4px',
        padding: '0px',
      }}
    >
      <div>
        {/* <span>Estratégia</span> */}
        <Tooltip title="Adicionar Estrategia" placement="right">
          <IconButton
            onClick={() => setStrategies(insert())}
            aria-label="adicionar"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </div>
      <div>
        {/* <span>Salvar Plano</span> */}
        <Tooltip title="Salvar Plano" placement="left">
          <IconButton
            onClick={() => dispatch(putLessonplan(uuid, put))}
            aria-label="save"
          >
            <SaveTwoToneIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default ButtonActions;
