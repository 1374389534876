import React from 'react';
import Typography from '../../../../components/typography';
import Insert from '../columns/actions/modal-insert';
import DataGrid from '../../../../components/datagrid';
import columns from '../columns';

export default ({ school }) =>
  school.length > 0 ? (
    <>
      <Typography>
        <i>Cadastrar Nova Escola?</i>
      </Typography>
      <Insert />

      <DataGrid
        title="Minhas Escolas Cadastradas:"
        rows={school}
        columns={columns}
      />
    </>
  ) : (
    <>
      <Typography>
        <i>Nenhuma escola cadastrada ainda.</i>
      </Typography>
      <Insert />
    </>
  );
