import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../../../../components/modal';

import { delUserSchool } from '../../../../../../state/reducers/user';

import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import DeleteIcon from '../../../../../base-components/delete-icon';
// const LinearProgress = Loadable(() =>
//   import('@material-ui/core/LinearProgress'),
// );

export default ({ params }) => {
  const dispatch = useDispatch();
  const [handle, setHandle] = React.useState(false);

  // const post = useSelector((state) =>)

  const SetAction = () => (
    <Tooltip title="Deletar" placement="right-end">
      <IconButton
        aria-label="deletar"
        onClick={() => {
          dispatch(delUserSchool(params.row.id));
          setHandle(false);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );

  const body = (
    <>
      <h3>Remover Escola da Lista?</h3>
      <p>Esta ação não poderá ser desfeita.</p>
    </>
  );

  const trigger = (
    <Tooltip title="Deletar" placement="right-end">
      <IconButton aria-label="deletar" onClick={() => setHandle(true)}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <>
      {params && params.value}

      <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
        {body}
      </Modal>
    </>
  );
};
