/* eslint-disable no-alert */
export const view = (old, uuid) => {
  try {
    const pdf = [];

    old.map((o) => {
      if (o.uuid === uuid) {
        pdf.push(o);
      }
      return o;
    });

    return pdf;
  } catch (error) {
    return console.log(error);
  }
};

export const insert = (old, payload) => {
  try {
    Object.assign(payload, { uuid: payload.uuid });
    old.push(payload);
    return old;
  } catch (error) {
    return console.log(error);
  }
};

export const update = (old, data, uuid) => {
  try {
    let it = [];
    if (old?.length > 0) {
      it = old.map((o) => {
        Object.entries(o).map(([key, value]) => {
          if (key === 'uuid' && value === uuid) {
            const n = Object.assign(o, data);

            return n;
          }
          return o;
        });
        return o;
      });

      return it;
    }
    return old;
  } catch (error) {
    return console.log(error);
  }
};

export const remove = (state, uuid) => {
  try {
    const newobj = [];
    state.filter((o) =>
      Object.entries(o).map(([key, value]) => {
        if (key === 'uuid' && value !== uuid) {
          newobj.push(o);
        }
        return o;
      }),
    );

    return newobj;
  } catch (error) {
    return console.log(error);
  }
};

export const errorMensageApi = (data) => {
  try {
    if (data.error.name === 'ValidationError') {
      return alert('Preencha todos os campos corretamente!');
    }
    return alert(data.error.message);

    // eslint-disable-next-line no-unreachable
  } catch (error) {
    return console.log(error);
  }
};
