import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import TextField from '../../../../../../components/text-input';
import InputChipsResources from '../../../../../../components/autocomplete-input-chips-resources';
import Card from '../styles';
import update from '../utils';
import IconButton from '../../../../../../base-components/icon-button';
import Tooltip from '../../../../../../base-components/tool-tip';
import SaveTwoToneIcon from '../../../../../../base-components/save-two-tone-icon';
import Typography from '../../../../../../base-components/typography';
import HighlightOffTwoToneIcon from '../../../../../../base-components/high-light-off-two-tone-icon';

const EditStrategies = ({ strategies, edit, setEdit }) => {
  const updateStrategies = (edt) => {
    setEdit(update(strategies, edt));
    setEdit([]);
  };

  const [edttime, setEdtTime] = useState(edit?.time);
  const [edtguidance, setEdtGuidance] = useState(edit?.guidance);
  const [edtpurpose, setEdtPurpose] = useState(edit?.purpose);
  const [edtdialogue, setEdtDialogue] = useState(edit?.dialogue);
  const [edtresources, setEdtResources] = useState(edit?.resources);

  return (
    <Card
      id={uuid()}
      style={{
        border: '0.2px solid',
        borderColor: '#028090',
        padding: '16px',
      }}
    >
      {/* <Typography id={uuid()} paragraph>
        <b>Estrategias</b>
      </Typography> */}

      <Typography id={uuid()} paragraph>
        <b>
          <i>{edit.step}ª Fase:</i>
        </b>
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: '0.2px solid',
          borderStyle: 'dotted',
          borderRadius: '4px',
          padding: '0px',
        }}
      >
        <div>
          {/* <span>Salvar</span> */}
          <Tooltip id={uuid()} title="Salvar" placement="right">
            <IconButton
              onClick={() =>
                updateStrategies({
                  step: edit.step,
                  time: edttime,
                  guidance: edtguidance,
                  purpose: edtpurpose,
                  dialogue: edtdialogue,
                  resources: edtresources,
                })
              }
              aria-label="salvar"
            >
              <SaveTwoToneIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          {/* <span>Cancelar</span> */}
          <Tooltip title="Cancelar" placement="left">
            <IconButton onClick={() => setEdit([])} aria-label="cancelar">
              <HighlightOffTwoToneIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <br />
      <TextField
        label="Duração"
        value={edttime}
        onChange={(e) => setEdtTime(e.target.value)}
      />
      <TextField
        label="Orientação"
        value={edtguidance}
        onChange={(e) => setEdtGuidance(e.target.value)}
      />
      <TextField
        label="Propósito"
        value={edtpurpose}
        onChange={(e) => setEdtPurpose(e.target.value)}
      />
      <InputChipsResources
        label="Fale com a turma"
        resources={edtdialogue}
        setResources={setEdtDialogue}
      />
      <InputChipsResources
        label="Atividades"
        resources={edtresources}
        setResources={setEdtResources}
      />
      <br />
      <br />
    </Card>
  );
};

export default EditStrategies;
