import React from 'react';
import { v4 as uuid } from 'uuid';
import Card, { Container } from './styles';
import Resources from './resources';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import EditTwoToneIcon from '../../../../../base-components/edit-two-tone-icon';
import DeleteForeverIcon from '../../../../../base-components/delete-forever-icon';
import Typography from '../../../../../base-components/typography';

const Strategies = ({ s, strategies, setStrategies, setEdit }) => {
  const removeAndReorder = (step) => {
    const f = strategies.filter((o) => o.step !== step);

    const reorder = f.map((o, i) => {
      Object.assign(o, { step: i + 1 });
      return o;
    });

    return reorder;
  };

  return (
    <Container>
      <Card
        style={{
          border: '0.2px solid',
          borderColor: '#028090',
          padding: '16px',
          borderRadius: '8px',
        }}
      >
        <Typography paragraph>
          <b>
            <i>{s.step}ª Fase:</i>
          </b>
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            border: '0.2px solid',
            borderStyle: 'dotted',
            borderRadius: '16px',
            padding: '0px',
          }}
        >
          <div>
            {/* <span>Editar</span> */}
            <Tooltip title="Editar Fase" placement="right">
              <IconButton onClick={() => setEdit(s)} aria-label="editar">
                <EditTwoToneIcon />
              </IconButton>
            </Tooltip>
          </div>
          <div>
            {/* <span>Remover</span> */}
            <Tooltip title="Remover Fase" placement="left">
              <IconButton
                onClick={() => setStrategies(removeAndReorder(s.step))}
                aria-label="remover"
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <br />
        <div key={uuid()}>
          <Typography paragraph>
            <i>Duração:</i> {s.time}
          </Typography>
          <Typography>
            <i>Orientação:</i>
          </Typography>
          <p
            style={{
              textIndent: '1.5em',
              textAlign: 'justify',
              textJustify: 'inter-word',
            }}
          >
            {s.guidance}
          </p>
          <Typography>
            <i>Propósito:</i>
          </Typography>
          <p
            style={{
              textIndent: '1.5em',
              textAlign: 'justify',
              textJustify: 'inter-word',
            }}
          >
            {s.purpose}
          </p>
          <Typography paragraph>
            <i>Fale com a turma:</i>
          </Typography>
          <ul>
            {s?.dialogue.map((p) => (
              <li key={uuid()}>{p}</li>
            ))}
          </ul>
          <Resources resources={s?.resources} />
        </div>
      </Card>
    </Container>
  );
};

export default Strategies;
