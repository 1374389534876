import React from 'react';
import styled from 'styled-components';
import Card from '../card';

const Cards = styled(({ ...props }) => <Card {...props} />)`
  & > div {
    margin-top: 8px;
  }
  border-radius: 8px;
`;

export default Cards;