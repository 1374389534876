import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import TextField from '../../../components/text-input';
import Cards from '../../../components/cards'
import ContainerAutcomplete from '../../../components/container-autocomplete';
import ChipsBncc from '../../../components/autocomplete-chips-bncc';
import ChipsSubjects from '../../../components/autocomplete-chips-subjects';
import ChipsClasses from '../../../components/autocomplete-chips-classes';
import RenderStrategies from './components/strategies/render-strategies';
import InputChipsResources from '../../../components/autocomplete-input-chips-resources';
import ButtonActions from './components/button-actions';
import { getLessonplanView } from '../../../../state/reducers/lessonplan';
import Typography from '../../../base-components/typography';

const CreateLessonPlan = ({ id }) => {
  const dispatch = useDispatch();

  React.useMemo(() => {
    dispatch(getLessonplanView(id));
  }, [dispatch]);

  const lessonplan = useSelector((state) => state.lessonplan[id][0]);

  const [title, setTitle] = useState(lessonplan?.title || '');
  const [bncc, setBncc] = useState(lessonplan?.bncc || []);
  const [appraisal, setAppraisal] = useState(lessonplan?.appraisal || '');
  const [subject, setSubject] = useState(lessonplan?.subject || '');
  const [hub, setHub] = useState(lessonplan?.hub || '');
  const [resources, setResources] = useState(
    lessonplan?.resources.split(';') || [],
  );
  const [classes, setClasses] = useState(lessonplan?.class || '');
  const [strategies, setStrategies] = useState(lessonplan?.strategies || []);
  const [edit, setEdit] = useState('');

  return (
    <ContainerAutcomplete>
      <Cards>
        <TextField
          label="Assunto"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <ChipsBncc
          id={uuid()}
          label="Entre com o código da BNCC ou uma palavra chave. Ex.: ef01ma03"
          bncc={bncc}
          setBncc={setBncc}
          strategies={strategies}
          setStrategies={setStrategies}
        />
        <TextField
          label="Objetivo"
          value={hub}
          onChange={(e) => setHub(e.target.value)}
        />
        <InputChipsResources
          label="Recursos"
          resources={resources}
          setResources={setResources}
        />
        <TextField
          label="Avaliação"
          value={appraisal}
          onChange={(e) => setAppraisal(e.target.value)}
        />
        <ChipsSubjects
          id={uuid()}
          label="Disciplina"
          subject={subject}
          setSubject={setSubject}
        />
        <ChipsClasses
          id={uuid()}
          label="Turma/Ano"
          classes={classes}
          setClasses={setClasses}
        />
        <br />
        {strategies.length > 0 && (
          <Typography paragraph>
            <b>Estratégias</b>
          </Typography>
        )}
        <RenderStrategies
          strategies={strategies}
          setStrategies={setStrategies}
          edit={edit}
          setEdit={setEdit}
        />
        <br />
        <ButtonActions
          strategies={strategies}
          setStrategies={setStrategies}
          put={{
            title,
            bncc,
            hub,
            resources: resources.join('; '),
            appraisal,
            subject,
            class: classes,
            strategies,
          }}
          uuid={lessonplan.uuid}
        />
        <br />
        <br />
      </Cards>
    </ContainerAutcomplete>
  );
};

export default CreateLessonPlan;
