import React from "react";
import { v4 as uuid } from 'uuid';
import InputChipsResources from '../../../../../components/autocomplete-input-chips-resources';

const AtividadesTextFieldStrategiesFase = ({ setEdtResources, edtresources }) => {
    return (
        <InputChipsResources
            id={uuid()}
            label="Atividades"
            resources={edtresources}
            setResources={setEdtResources}
        />
    )
}

export default AtividadesTextFieldStrategiesFase;