import React from "react";
import { v4 as uuid } from 'uuid';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import EditTwoToneIcon from '../../../../../base-components/edit-two-tone-icon';

const EditStrategiesFase = ({ setEdit, s }) => {

    return (
        <div>
            {/* <span>Editar</span> */}
            <Tooltip title="Editar Fase" placement="right">
                <IconButton
                    id={uuid()}
                    onClick={() => setEdit(s)}
                    aria-label="editar"
                >
                    <EditTwoToneIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default EditStrategiesFase;