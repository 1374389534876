import React from "react";
import { v4 as uuid } from 'uuid';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import DeleteForeverIcon from '../../../../../base-components/delete-forever-icon';

const RemoveStrategiesFase = ({ setStrategies, s, strategies }) => {
    const removeAndReorder = (step) => {
        const f = strategies.filter((o) => o.step !== step);

        const reorder = f.map((o, i) => {
            Object.assign(o, { step: i + 1 });
            return o;
        });

        return reorder;
    };

    return (
        <div>
            {/* <span>Remover</span> */}
            <Tooltip title="Remover Fase" placement="left">
                <IconButton
                    id={uuid()}
                    onClick={() => setStrategies(removeAndReorder(s.step))}
                    aria-label="remover"
                >
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default RemoveStrategiesFase;