import React from 'react';
import Typography from '../../../../components/typography';

export default ({ data }) => (
  <Typography paragraph>
    <p>
      <b>
        <i>Escola: {data.event.extendedProps.schoolschedule[0].name}</i>
      </b>
    </p>
    <p>
      <i>Diretor(a): {data.event.extendedProps.schoolschedule[0].dir}</i>
    </p>
    <p>
      <i>Coordenador(a): {data.event.extendedProps.schoolschedule[0].coord}</i>
    </p>
    <p>
      <i>Professor(a): {data.event.extendedProps.schoolschedule[0].prof}</i>
    </p>
    <p>
      <i>Turma: {data.event.extendedProps.schoolschedule[0].class}</i>
    </p>
    <p>
      <i>Disciplina: {data.event.extendedProps.schoolschedule[0].subject}</i>
    </p>
    <p>
      <b>
        <i>Horário: {data.event.extendedProps.schoolschedule[0].time}</i>
      </b>
    </p>
  </Typography>
);
