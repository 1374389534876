import React from 'react';
import AddStrategiesFase from '../../components/add-strategies-fase';
import SaveLessonPlan from '../../components/save-lesson-plan';
import BarDotted from '../../../../../components/bar-dotted';

const BarButtonActions = ({ strategies, setStrategies, post }) => {


  return (
    <>
      <BarDotted>
        <AddStrategiesFase setStrategies={setStrategies} strategies={strategies} />
        <SaveLessonPlan post={post} />
      </BarDotted>
    </>
  );
};

export default BarButtonActions;
