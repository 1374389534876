import React from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazy-load';
import Avatar from '../../base-components/avatar';
import PageviewIcon from '@material-ui/icons/Pageview';
import Typography from '../typography';
import Link from '../link';
import { neutral } from '../colors';
import AppBar from '../../base-components/app-bar';
import './styles.css';

export const Root = styled.div`
  flex-grow: 1;
`;

export const App = styled(({ children, ...props }) => (
  <AppBar {...props} position="static">
    {children}
  </AppBar>
))`
  display: flex;
  justify-content: center;
  min-height: 4rem;
  min-width: 100%;
  background-color: ${neutral};
  padding-left: 1rem;
  padding-right: 1rem;
  & > div {
    display: flex;
    align-items: center;
    /* text-transform: capitalize; */
  }
  @media (min-width: 600px) {
    .MuiToolbar-regular {
      min-height: 4rem;
    }
  }
  
`;

export const Title = styled(({ logo, ...props }) => (
  <Typography {...props} variant="h6">
    <LazyLoad debounce={false} offsetVertical={42}>
      <Link
        to="/"
        text={
          <Avatar src={logo} width="36px" height="36px" alt="Logo">
            <PageviewIcon />
          </Avatar>
        }
      />
    </LazyLoad>
  </Typography>
))`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-top: 4px;
`;
