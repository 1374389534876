import React, { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
  getLessonplanView,
  reportLessonplan,
} from '../../../state/reducers/lessonplan';
import Card from '../../components/card';
import Container from '../../components/container';

export default ({ id, header }) => {
  const objectRef = useRef(null)
  const [height, setHeight] = useState(0)

  const dispatch = useDispatch();

  React.useMemo(() => {
    console.log(objectRef);
    setHeight(objectRef.current?.offsetHeight);
    dispatch(getLessonplanView(id));
  }, [dispatch, setHeight]);

  const view = useSelector((state) => state?.lessonplan[`${id}`][0]);

  const report = useSelector((state) => state.lessonplan.report);

  const lp = Object.assign(view, { header: JSON.parse(atob(header)) });

  React.useMemo(() => {
    dispatch(reportLessonplan(lp));
  }, [dispatch]);

  return view.uuid === id ? (
    <Container>
      <Card
        ref={objectRef}
        style={
          {
            display: 'flex',
            height: "100%"
          }
        }
      >

        <br />
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <object
          data={report + "#view=FitH"}
          width="100%"
          height={height}
          type="application/pdf"
        />

      </Card>
    </Container >
  ) : (
    <Container>
      <Card>
        <div>Loading...</div>
      </Card>
    </Container >
  );
};
