import SET_LESSONPLAN_HEADER from './consts';

import initialState from './states';

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LESSONPLAN_HEADER:
      return {
        ...state,
        lessonplanheader: action.payload,
      };

    default:
      return state;
  }
};
