import React from 'react';
import { v4 as uuid } from 'uuid';
import Typography from '../../../../../base-components/typography';

const Resources = ({ resources }) => (
  <div key={uuid()}>
    <Typography paragraph>
      <i>Atividades complementares:</i>
    </Typography>
    <ul>
      {resources.map((k, r) => (
        <li key={uuid()}>
          <a key={uuid()} href={k}>
            Atividade {r + 1}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Resources;
