import React from 'react';
import { v4 as uuid } from 'uuid';
import PageviewIcon from '@material-ui/icons/Pageview';
import { useDispatch } from 'react-redux';
import Avatar from '../../../../../base-components/avatar';
import TextField from '../../../../../components/text-input';
import Modal from '../../../../../components/modal';
import { putUserSchool } from '../../../../../../state/reducers/user';
import { Center } from '../../../../../components/modal-items';
import IconButton from '../../../../../base-components/icon-button'
import Tooltip from '../../../../../base-components/tool-tip'
import SaveIcon from '../../../../../base-components/save-icon'
import EditIcon from '../../../../../base-components/edit-icon'

export default ({ params }) => {
  const dispatch = useDispatch();
  // getModalStyle is not a pure function, we roll the style only on the first render

  const [handle, setHandle] = React.useState(false);
  const [name, setName] = React.useState(params.row.name);
  const [dir, setDir] = React.useState(params.row.dir);
  const [coord, setCoord] = React.useState(params.row.coord);
  const [url, setURL] = React.useState(params.row.url);
  const [mouse, setMouse] = React.useState(false);

  const SetAction = () => (
    <Tooltip title="Salvar" placement="right-end">
      <IconButton
        aria-label="salvar"
        onClick={() => {
          dispatch(putUserSchool(params.row.id, { url, name, dir, coord }));
          setHandle(false);
        }}
      >
        <SaveIcon />
      </IconButton>
    </Tooltip>
  );

  const body = (
    <>
      <h3>Atualizar Escola?</h3>
      <div
        onMouseEnter={() => setMouse(true)}
        onMouseLeave={() => setMouse(false)}
      >
        {mouse ? (
          <TextField
            id={uuid()}
            label="Link da Logo."
            style={{ width: '100%' }}
            value={url}
            onChange={(e) => setURL(e.target.value)}
          />
        ) : (
          <Avatar alt="Logo" src={url}>
            <PageviewIcon />
          </Avatar>
        )}
      </div>
      <Center>
        <TextField
          id={uuid()}
          label="Escola"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          id={uuid()}
          label="Diretor(a)"
          value={dir}
          onChange={(e) => setDir(e.target.value)}
        />
        <TextField
          id={uuid()}
          label="Coordenador(a)"
          value={coord}
          onChange={(e) => setCoord(e.target.value)}
        />
      </Center>
    </>
  );

  const trigger = (
    <Tooltip title="Editar" placement="right-end">
      <IconButton
        aria-label="editar"
        // eslint-disable-next-line no-return-assign
        onClick={() => setHandle(true)}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
      {body}
    </Modal>
  );
};
