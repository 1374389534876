export const primary = '#304188';

export const primaryLight = '#d9e2f1';

export const primaryDark = '#312D2A';

export const secondary = '';

export const secondaryLight = '#1b3a57';

export const secondaryDark = '#031525';

export const $neutralDarker = '';

export const $neutrayDark = '';

export const neutral = '#fff';

export const neutralLight = '';

export const neutralLighter = '';

export const $neutralLightest = '';
