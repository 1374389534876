import { takeEvery } from 'redux-saga/effects';
import * as effects from '../effects';

export function* getWatcherSaga() {
  yield takeEvery('API_METHOD_GET', effects.getEffectSaga);
}

export function* postWatcherSaga() {
  yield takeEvery('API_METHOD_POST', effects.postEffectSaga);
}

export function* putWatcherSaga() {
  yield takeEvery('API_METHOD_PUT', effects.putEffectSaga);
}

export function* deleteWatcherSaga() {
  yield takeEvery('API_METHOD_DELETE', effects.deleteEffectSaga);
}

export function* signinWatchSaga() {
  yield takeEvery('API_METHOD_POST_LOGIN', effects.signinEffectSaga);
}

export function* reportWatchSaga() {
  yield takeEvery('API_METHOD_REPORT', effects.reportEffectSaga);
}
