import React from 'react';
import { Router } from '@reach/router';
import Loadable from '@loadable/component';
import { PrivateRoute } from '../../routes';
import LazyLoading from '../../app/components/lazy-loading';
import * as App from '../../app/private';

const NotFound = Loadable(() => import('../404'), {
    fallback: <LazyLoading />,
});

const AppRoute = () => (
    <Router basepath="/app">
        <NotFound path="*" />
        <PrivateRoute path="/" component={App.Search} title="PA - Procurar" />
        <PrivateRoute
            path="/calendar"
            component={App.Calendar}
            title="PA - Planejamento"
        />
        <PrivateRoute
            path="/school"
            component={App.School}
            title="PA - Escola"
        />
        <PrivateRoute
            path="/lessonplan/:id/view"
            component={(props) => <App.View {...props} />}
            title="PA - Visualização"
        />
        <PrivateRoute
            path="/lessonplan/:id/edit"
            component={(props) => <App.Edit {...props} />}
            title="PA - Editar"
        />
        <PrivateRoute
            path="/lessonplan/:id/report/:header"
            component={(props) => <App.Report {...props} />}
            title="PA - Relatório"
        />
        <PrivateRoute
            path="/settings"
            component={App.Settings}
            title="PA - Configurações"
        />
    </Router>
);

export default AppRoute;
