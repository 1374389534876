import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import Card from '../../../components/card';
import Container from '../../../components/container';
import RenderStrategies from './group-strategies/render-strategies';
import BarButtonActions from './group-strategies/bar-button-actions';
import Typography from '../../../base-components/typography';
import AssuntoTextFieldLessonplan from './components/assunto-textfield-lessonplan';
import BnccChipsLessonplan from './components/bncc-chips-lessonplan';
import ObjetivoTextFieldLessonplan from './components/objetivo-textfield-lessonplan';
import RecursosInputChipsLessonplan from './components/recursos-inputchips-lessonplan';
import AvaliacaoTextFieldLessonplan from './components/avaliacao-textfield-lessonplan';
import DisciplinaInputChipsLessonplan from './components/disciplina-inputchips-lessonplan';
import ClassesInputChipsLessonplan from './components/classes-inputchips-lessonplan';

const CreateLessonPlan = () => {
  const [title, setTitle] = useState('');
  const [bncc, setBncc] = useState([]);
  const [appraisal, setAppraisal] = useState('');
  const [subject, setSubject] = useState('');
  const [hub, setHub] = useState('');
  const [resources, setResources] = useState([]);
  const [classes, setClasses] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [edit, setEdit] = useState('');

  return (
    <Container>
      <Card>
        {/** AssuntoTextFieldLessonplan */}
        <AssuntoTextFieldLessonplan setTitle={setTitle} title={title} />
        {/** BNCCChipLessonplan */}
        <BnccChipsLessonplan
          bncc={bncc}
          setBncc={setBncc}
          strategies={strategies}
          setStrategies={setStrategies}
        />
        {/** ObjetivoTextFieldLessonplan */}
        <ObjetivoTextFieldLessonplan setHub={setHub} hub={hub} />
        {/** RecursosInputChipsLessonplan */}
        <RecursosInputChipsLessonplan setResources={setResources} resources={resources} />
        {/** AssuntoTextFieldLessonplan */}
        <AvaliacaoTextFieldLessonplan setAppraisal={setAppraisal} appraisal={appraisal} />
        {/** DisciplinaInputChipsLessonplan */}
        <DisciplinaInputChipsLessonplan setSubject={setSubject} subject={subject} />
        {/** AssuntoTextFieldLessonplan */}
        <ClassesInputChipsLessonplan setClasses={setClasses} classes={classes} />
        <br />
        {/** AssuntoTextFieldLessonplan */}

        <Typography paragraph>
          <b>Orientações</b>
        </Typography>

        <RenderStrategies
          strategies={strategies}
          setStrategies={setStrategies}
          edit={edit}
          setEdit={setEdit}
        />
        <br />
        {/** AssuntoTextFieldLessonplan */}
        <BarButtonActions
          strategies={strategies}
          setStrategies={setStrategies}
          post={{
            uuid: uuid(),
            title,
            bncc,
            hub,
            resources: resources.join('; '),
            appraisal,
            subject,
            class: classes,
            strategies,
          }}
        />
        <br />
        <br />
      </Card>
    </Container>
  );
};

export default CreateLessonPlan;
