export default [
  {
    id: 1,
    content: 'Maternal I/Infantil',
  },
  {
    id: 2,
    content: 'Maternal II/Infantil',
  },
  {
    id: 3,
    content: 'Jardim I/Infantil',
  },
  {
    id: 4,
    content: 'Jardim II/Infantil',
  },
  {
    id: 5,
    content: 'Jardim III/Infantil',
  },
  {
    id: 6,
    content: '1º Ano/Fundamental I',
  },
  {
    id: 7,
    content: '2º Ano/Fundamental I',
  },
  {
    id: 8,
    content: '3º Ano/Fundamental I',
  },
  {
    id: 9,
    content: '4º Ano/Fundamental I',
  },
  {
    id: 10,
    content: '5º Ano/Fundamental I',
  },
  {
    id: 11,
    content: '6º Ano/Fundamental II',
  },
  {
    id: 12,
    content: '7º Ano/Fundamental II',
  },
  {
    id: 13,
    content: '8º Ano/Fundamental II',
  },
  {
    id: 14,
    content: '9º Ano/Fundamental II',
  },
  {
    id: 15,
    content: '1º Ano/Médio',
  },
  {
    id: 16,
    content: '2º Ano/Médio',
  },
  {
    id: 17,
    content: '3º Ano/Médio',
  },
];
