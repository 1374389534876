import React from "react";
import { v4 as uuid } from 'uuid';
import ChipsClasses from '../../../../../components/autocomplete-chips-classes';

const ClassesInputChipsLessonplan = ({ setClasses, classes }) => {
    return (
        <ChipsClasses
            id={uuid()}
            label="Turma/Ano"
            classes={classes}
            setClasses={setClasses}
        />
    )
}

export default ClassesInputChipsLessonplan;