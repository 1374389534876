import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../../../../components/modal';
import { delComments } from '../../../../../../state/reducers/comments';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import DeleteIcon from '../../../../../base-components/delete-icon';

export default ({ params }) => {
  const dispatch = useDispatch();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [handle, setHandle] = React.useState(false);

  const SetAction = () => (
    <Tooltip title="Deletar" placement="right-end">
      <IconButton
        aria-label="deletar"
        onClick={() => {
          dispatch(delComments(params.row.id));
          setHandle(false);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );

  const body = (
    <>
      <h3>Remover comentário?</h3>
      <p>Esta ação não poderá ser desfeita!</p>
    </>
  );

  const trigger = (
    <Tooltip title="Deletar" placement="right-end">
      <IconButton aria-label="deletar" onClick={() => setHandle(true)}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
      {body}
    </Modal>
  );
};
