import * as calendarevents from './consts';

import initialState from './states';

export default (state = initialState, action) => {
  switch (action.type) {
    case calendarevents.VIEW_CALENDAR_EVENTS:
      return { ...state, get: true, calendarevents: action.payload || [] };

    case calendarevents.VIEW_CALENDAR_EVENT:
      return {
        ...state,
        get: true,
        // eslint-disable-next-line no-underscore-dangle
        [`${action.payload[0].uuid}`]: action.payload || [],
      };

    case calendarevents.INSERT_CALENDAR_EVENTS:
      return {
        ...state,
        post: true,
        calendarevents: action.payload || [],
      };

    case calendarevents.MODIFY_CALENDAR_EVENTS:
      return {
        ...state,
        put: true,
        calendarevents: action.payload || [],
      };

    case calendarevents.REMOVE_CALENDAR_EVENTS:
      return {
        ...state,
        delete: true,
        calendarevents: action.payload || [],
      };

    case calendarevents.FLAG_CALENDAR_EVENTS_GET:
      return {
        ...state,
        get: false,
      };

    case calendarevents.FLAG_CALENDAR_EVENTS_POST:
      return {
        ...state,
        post: false,
      };

    case calendarevents.FLAG_CALENDAR_EVENTS_PUT:
      return {
        ...state,
        put: false,
      };

    case calendarevents.FLAG_CALENDAR_EVENTS_DELETE:
      return {
        ...state,
        delete: false,
      };

    case 'SET_DATE':
      return {
        ...state,
        date: action.payload,
      };

    default:
      return state;
  }
};
