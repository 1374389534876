import * as actions from './consts';

export const getUserLessonplan = () => ({
    type: 'API_METHOD_GET',
    subtype: actions.GET_USER_LESSONPLAN,
    flag: actions.FLAG_USER_GET,
    path: '/user/lessonplan',
});

export const getUserCalendar = () => ({
    type: 'API_METHOD_GET',
    subtype: actions.GET_USER_CALENDAR,
    flag: actions.FLAG_USER_GET,
    path: '/user/calendar',
});

export const getUser = () => ({
    type: 'API_METHOD_GET',
    subtype: actions.GET_USER,
    flag: actions.FLAG_USER_GET,
    path: '/user',
});

export const getUserSchool = () => ({
    type: 'API_METHOD_GET',
    subtype: actions.GET_USER_SCHOOL,
    flag: actions.FLAG_USER_SCHOOL_GET,
    path: '/user/school',
});

export const postUserSchool = (payload) => ({
    type: 'API_METHOD_POST',
    subtype: actions.POST_USER_SCHOOL,
    flag: actions.FLAG_USER_SCHOOL_POST,
    state: 'user',
    intercept: 'school',
    path: '/user/school',
    payload,
});

export const postUserCalendar = (payload) => ({
    type: 'API_METHOD_POST',
    subtype: actions.POST_USER_CALENDAR,
    flag: actions.FLAG_USER_POST,
    state: 'user',
    intercept: 'calendar',
    path: '/user/calendar',
    payload,
});

export const putUserSchool = (uuid, payload) => ({
    type: 'API_METHOD_PUT',
    subtype: actions.PUT_USER_SCHOOL,
    flag: actions.FLAG_USER_SCHOOL_PUT,
    state: 'user',
    intercept: 'school',
    path: `/user/school/${uuid}`,
    uuid,
    payload,
});

export const putUserCalendar = (uuid, payload) => ({
    type: 'API_METHOD_PUT',
    subtype: actions.PUT_USER_CALENDAR,
    flag: actions.FLAG_USER_PUT,
    state: 'user',
    intercept: 'calendar',
    path: `/user/calendar/${uuid}`,
    uuid,
    payload,
});

export const putUser = (payload) => ({
    type: 'API_METHOD_PUT',
    subtype: actions.PUT_USER,
    flag: actions.FLAG_USER_PUT,
    state: 'user',
    intercept: 'user',
    path: '/user',
    payload,
});

export const delUserSchool = (uuid) => ({
    type: 'API_METHOD_DELETE',
    subtype: actions.DEL_USER_SCHOOL,
    flag: actions.FLAG_USER_SCHOOL_DEL,
    state: 'user',
    intercept: 'school',
    path: `/user/school/${uuid}`,
    uuid,
});

export const delUserCalendar = (uuid) => ({
    type: 'API_METHOD_DELETE',
    subtype: actions.DEL_USER_CALENDAR,
    flag: actions.FLAG_USER_DEL,
    state: 'user',
    intercept: 'calendar',
    path: `/user/calendar/${uuid}`,
    uuid,
});
