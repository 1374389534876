import styled from 'styled-components';

export const ModalBody = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 400px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 5px;
  @media (max-width: 840px) {
    width: 95%;
  }
`;

export const ModalContent = styled.div`
  padding: 16px;
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
