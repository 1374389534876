import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import Card from '../styles';
import Typography from '../../../../../base-components/typography';
import BarStrategiesEditTools from '../bar-strategies-edit-tools';
import DuracaoTextFieldStrategiesFase from '../../components/duracao-textfield-strategies-fase';
import OrientacaoTextFieldStrategiesFase from '../../components/orientacao-textfield-strategies-fase';
import PropositoTextFieldStrategiesFase from '../../components/proposito-textfield-strategies-fase';
import FalecaTurmaextFieldStrategiesFase from '../../components/falecaturma-textfield-strategies-fase';
import AtividadesTextFieldStrategiesFase from '../../components/atividades-textfield-strategies-fase';

const EditStrategies = ({ strategies, edit, setEdit }) => {

  const [edttime, setEdtTime] = useState(edit?.time);
  const [edtguidance, setEdtGuidance] = useState(edit?.guidance);
  const [edtpurpose, setEdtPurpose] = useState(edit?.purpose);
  const [edtdialogue, setEdtDialogue] = useState(edit?.dialogue);
  const [edtresources, setEdtResources] = useState(edit?.resources);

  return (
    <Card
      id={uuid()}
      style={{
        border: '0.2px solid',
        borderColor: '#028090',
        padding: '16px',
      }}
    >
      {/* <Typography id={uuid()} paragraph>
        <b>Estrategias</b>
      </Typography> */}

      <Typography id={uuid()} paragraph>
        <b>
          <i>{edit.step}ª Fase:</i>
        </b>
      </Typography>

      {/** BarStrategiesEditTools */}
      <BarStrategiesEditTools
        setEdit={setEdit}
        strategies={strategies}
        edit={edit}
        edttime={edttime}
        edtguidance={edtguidance}
        edtpurpose={edtpurpose}
        edtdialogue={edtdialogue}
        edtresources={edtresources}
      />
      <br />

      <br />
      {/* textfield Duração */}
      <DuracaoTextFieldStrategiesFase setEdtTime={setEdtTime} edttime={edttime} />
      {/* textfield Orientação */}
      <OrientacaoTextFieldStrategiesFase setEdtGuidance={setEdtGuidance} edtguidance={edtguidance} />
      {/* textfield Propósito */}
      <PropositoTextFieldStrategiesFase setEdtPurpose={setEdtPurpose} edtpurpose={edtpurpose} />
      {/* textfield Fale com a turma */}
      <FalecaTurmaextFieldStrategiesFase setEdtDialogue={setEdtDialogue} edtdialogue={edtdialogue} />
      {/* textfield Atividades */}
      <AtividadesTextFieldStrategiesFase setEdtResources={setEdtResources} edtresources={edtresources} />
      <br />
    </Card>
  );
};

export default EditStrategies;
