import React from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuid } from 'uuid';
import insertComment from './redux/insert-comment';
import Typography from '../typography';
import TextField from '../text-input';
import { Center } from '../datagrid/styles/styled';
import Modal from '../modal';
import IconButton from '../../base-components/icon-button';
import Rating from '../../base-components/rating';
import Tooltip from '../../base-components/tool-tip';
import AddBoxIcon from '../../base-components/add-box-icon';
import ChatIcon from '../../base-components/chat-icon';
import FormControlLabel from '../../base-components/form-control-label';
import Checkbox from '../../base-components/check-box';

export default ({ lessonplanuuid }) => {
  const dispatch = useDispatch();
  const [handle, setHandle] = React.useState(false);
  // const [insert, setInsert] = React.useState(false);
  const [score, setScore] = React.useState(0);
  const [content, setContent] = React.useState('');
  const [guest, setGuest] = React.useState(false);

  const SetAction = () => (
    <Tooltip title="Adicionar" placement="right-end">
      <IconButton
        aria-label="adicionar"
        onClick={() => {
          dispatch(
            insertComment(lessonplanuuid, {
              uuid: uuid(),
              score,
              content,
              guest,
            }),
          );
          setHandle(false);
        }}
        // disabled={!(lid !== '' && ltitle !== '')}
      >
        <AddBoxIcon />
      </IconButton>
    </Tooltip>
  );

  const body = (
    <>
      <h3 id="simple-modal-title">Adicionar Comentário?</h3>
      <p id="simple-modal-description">
        Comente e dê uma nota para o plano de aula.
      </p>

      <Center>
        <TextField
          id={uuid()}
          label="Comentário"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <Typography component="span">
          Nota:{' '}
          <Rating
            name="score"
            value={score}
            onChange={(event, newValue) => {
              setScore(newValue);
            }}
          />
        </Typography>

        <FormControlLabel
          control={
            <Checkbox
              checked={guest}
              onChange={() => setGuest(!guest)}
              name="chkGuest"
              color="primary"
            />
          }
          label="Comentar Como Desconhecido?"
        />
      </Center>
    </>
  );

  const trigger = (
    <Tooltip title="Comentar" placement="left">
      <IconButton aria-label="comentar" onClick={() => setHandle(true)}>
        <ChatIcon />
      </IconButton>
    </Tooltip>
  );

  return (
    <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
      {body}
    </Modal>
  );
};
