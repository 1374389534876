import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
`;
