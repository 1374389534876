const initialState = {
    get: false,
    getview: false,
    post: false,
    put: false,
    del: false,
    comments: [],
    view: [],
    print: [],
};

export default initialState;
