import React from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

const Seo = ({ title, description }) => {
  const Script = () => {
    const html = document.getElementsByTagName('html');
    html[0].setAttribute('lang', 'pt-br');
    return null;
  };

  return (
    <>
      <Script />
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {/* <script
      data-ad-client="ca-pub-4138504392994443"
      async
      src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
    /> */}
    </>
  );
};

Seo.defaultProps = {
  description:
    'Somos um grupo de professores, empenhados a difundir as boas práticas. Contribuindo e compartilhando conhecimento. Buscando ferramentas para obter formas de ensino com excelência.',
};

Seo.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default Seo;
