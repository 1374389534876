/* eslint-disable no-unused-expressions */
/* eslint-disable no-bitwise */
// Criar objeto Base64
export default (s) => {
    const e = {};
    let i = null;
    let b = 0;
    let c = null;
    let x = null;
    let l = 0;
    let a = null;
    let r = '';
    const w = String.fromCharCode;
    const L = s.length;
    const A =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < 64; i++) {
        e[A.charAt(i)] = i;
    }
    // eslint-disable-next-line no-plusplus
    for (x = 0; x < L; x++) {
        c = e[s.charAt(x)];
        // eslint-disable-next-line no-bitwise
        b = (b << 6) + c;
        l += 6;
        while (l >= 8) {
            ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
        }
    }
    return r;
};
