import React from 'react';
import Popover from '../../../../components/popover';
import Info from './info';

const EventTitle = ({ data }) => (
  <Popover data={data}>
    <Info data={data} />
  </Popover>
);

export default EventTitle;
