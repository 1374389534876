import moment from '../../../../components/moment';

const initialState = {
  get: false,
  pdf: false,
  post: false,
  put: false,
  delete: false,
  calendarevents: [],
  view: [],
  date: {
    start: moment().startOf('month').format('YYYY-MM-DD'),
    end: moment().endOf('month').format('YYYY-MM-DD'),
  },
};

export default initialState;
