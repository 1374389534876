import React from 'react';
import Loadable from '@loadable/component';
import LazyLoading from '../components/lazy-loading';

// const Calendar

export const Calendar = Loadable(() => import('./planning'), {
  fallback: <LazyLoading />,
});

export const Settings = Loadable(() => import('./settings'), {
  fallback: <LazyLoading />,
});

export const Layout = Loadable(() => import('./layout'), {
  fallback: <LazyLoading />,
});

export const Search = Loadable(() => import('./search'), {
  fallback: <LazyLoading />,
});

export const View = Loadable(() => import('./view'), {
  fallback: <LazyLoading />,
});

export const Edit = Loadable(() => import('./edit'), {
  fallback: <LazyLoading />,
});

export const Report = Loadable(() => import('./report'), {
  fallback: <LazyLoading />,
});
// export { default as Editor } from './editor';
