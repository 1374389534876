/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import { call, put, select } from 'redux-saga/effects';
import * as api from './api';

import * as Utils from './utils';

export default function* reportEffectSaga(action) {
    try {
        yield put({ type: action.flag }); // set status false

        const state = yield select();

        const { jwt } = state.auth;
        // get data api from axios post
        const { data } = yield call(
            api.apiReport,
            action.path,
            action.payload,
            jwt,
        );

        if (data.code === 1) {
            yield put({
                type: action.subtype,
                code: data.code,
                msg: data.message,
                payload: data.data,
            });

            return alert(data.message);
        }
        if (data.code === 0) {
            return Utils.errorMensageApi(data);
        }
    } catch (e) {
        alert(e);
    }
}
