import React from 'react';
import styled from 'styled-components';
import IconButton from '../../../../base-components/icon-button';
import Tooltip from '../../../../base-components/tool-tip';
import { secondaryDark } from '../../../../components/colors';
import Autocomplete from  '../../../../base-components/autocomplete';
import EditIcon from '../../../../base-components/edit-icon';
import CloseIcon from '../../../../base-components/close-icon';
import MenuIcon from '../../../../base-components/menu-icon';
import InfoIcon from '../../../../base-components/info-icon';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin-top: 24px;
`;

export const Input = styled(Autocomplete)`
  font-size: 1rem;
  div {
    /* border-radius: 24px; */
  }
  label {
    margin-left: 4px;
  }
`;

export const IconButtonDayContent = styled(IconButton)`
  color: ${secondaryDark};
  font-size: 0.75rem;
  &:hover {
    color: #fff;
    background-color: #1a252f;
  }
`;
// box-shadow: 1px 1px;

export const IconButtonRemoveEvent = styled((props) => (
  <Tooltip title="Remover">
    <IconButton aria-label="remover" {...props}>
      <CloseIcon />
    </IconButton>
  </Tooltip>
))`
  width: 16px;
  height: 16px;
  .MuiSvgIcon-root {
    font-size: 0.725rem;
  }
  &:hover {
    color: #fff;
    background-color: #1a252f;
  }
  & > div {
  }
`;

export const IconButtonEditEvent = styled((props) => (
  <Tooltip title="Editar">
    <IconButton aria-label="editar" {...props}>
      <EditIcon />
    </IconButton>
  </Tooltip>
))`
  width: 16px;
  height: 16px;
  .MuiSvgIcon-root {
    font-size: 0.725rem;
  }
  &:hover {
    color: #fff;
    background-color: #1a252f;
  }
  & > div {
  }
`;

export const IconButtonMenuEvent = styled((props) => (
  <Tooltip title="Menu">
    <IconButton aria-label="menu" {...props}>
      <MenuIcon />
    </IconButton>
  </Tooltip>
))`
  width: 16px;
  height: 16px;
  .MuiSvgIcon-root {
    font-size: 0.725rem;
  }
  &:hover {
    color: #fff;
    background-color: #1a252f;
  }
  & > div {
  }
`;

export const IconButtonInfoEvent = styled((props) => (
  <Tooltip title="Informação">
    <IconButton aria-label="informação" {...props}>
      <InfoIcon />
    </IconButton>
  </Tooltip>
))`
  width: 16px;
  height: 16px;
  .MuiSvgIcon-root {
    font-size: 0.725rem;
  }
  &:hover {
    color: #fff;
    background-color: #1a252f;
  }
  & > div {
  }
`;
