import React from 'react';
import styled from 'styled-components';
import Card from '../../base-components/card'

export default styled(({ ...props }) => <Card {...props} />)`
  margin-top: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  min-height: calc(80vh - 62px);

  border: 1px solid rgba(0, 0, 0, 0.1 7);

  border-radius: 4px;
  & > div {
    margin-top: 8px;
  }
  .MuiTypography-root {
    font-size: 0.75rem;
  }
`;