import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';

import * as reducers from './reducers';

import * as configs from './persistConfig';

export default combineReducers({
    app: persistReducer(configs.appPersistConfig, reducers.app),
    auth: persistReducer(configs.authPersistConfig, reducers.auth),
    user: persistReducer(configs.userPersistConfig, reducers.user),
    lessonplan: persistReducer(
        configs.lessonplanPersistConfig,
        reducers.lessonplan,
    ),
    calendar: reducers.calendar,
    header: reducers.header,
    comments: persistReducer(configs.commentsPersistConfig, reducers.comments),
    lessonplanheader: reducers.lessonplanheader,
});
