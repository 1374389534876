import React from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import ContainerAutcomplete from '../container-autocomplete';
import InputAutocomplete from '../input-autocomplete';
import filterSearch from '../../utils/filterSearch';
import TextField from '../text-input';

const AutoCompleteInputLessonplan = ({ setID, setTitle }) => {
  const lessonplan = useSelector((state) => state.user.lessonplan);
  return (
    <ContainerAutcomplete id={uuid()}>
      <InputAutocomplete
        id={uuid()}
        filterSelectedOptions
        options={lessonplan}
        getOptionLabel={(option) => option.title}
        filterOptions={(option, state) => {
          if (state.inputValue.length > 5) {
            return option.filter((o) => filterSearch(state.inputValue, o));
          }
          return { title: 'vazio' };
        }}
        onChange={(event, newValue, reason) => {
          if (reason === 'select-option') {
            setID(newValue.uuid);
            setTitle(newValue.title);
          }

          return newValue;
        }}
        renderInput={(params) => (
          <TextField
            id={uuid()}
            {...params}
            label="Procurar plano de aula..."
            // value={ltitle}
          />
        )}
        fullWidth
      />
    </ContainerAutcomplete>
  );
};

export default AutoCompleteInputLessonplan;
