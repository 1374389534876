import Calendar from './customfullcalendar';
import Card from '../../../components/card';
import Container from '../../../components/container';

const Calendars = () => (
    <Container>
        <Card>
            <Calendar />
        </Card>
    </Container>
)

export default Calendars;
