import React from 'react';
import Delete from './modal-delete';
import Update from './modal-update';
// export { default as Insert } from './modal-insert';

export default ({ params }) => (
  <>
    <Delete params={params} /> <Update params={params} />
  </>
);
