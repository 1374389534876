import { createTheme } from '@mui/material/styles';
import { ptBR } from '@mui/x-data-grid';

export default createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  ptBR,
);
