import * as types from './consts';

export const setIsMobile = (value) => ({
    type: types.IS_MOBILE,
    value,
});

export const setTabPlan = (value) => ({
    type: types.STATUS_PLANNING_TABS_PLAN,
    value,
});

export const setTabSchool = (value) => ({
    type: types.STATUS_PLANNING_TABS_SCHOOL,
    value,
});

export const setTabSchoolClass = (value) => ({
    type: types.STATUS_PLANNING_TABS_SCHOOL_CLASS,
    value,
});