/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from './styled';
import Typography from '../typography';

export default ({ text, to }) => (
  // const preventDefault = (event) => event.preventDefault();

  <Link to={to}>
    <Typography>{text}</Typography>
  </Link>
);
