import storage from 'redux-persist/lib/storage';

export const appPersistConfig = {
  key: 'app',
  storage,
  //   blacklist: ["auth"],
};

export const authPersistConfig = {
  key: 'auth',
  storage,
};

export const userPersistConfig = {
  key: 'user',
  storage,
};

export const lessonplanPersistConfig = {
  key: 'lessonplan',
  storage,
};

export const commentsPersistConfig = {
  key: 'comments',
  storage,
};

// export const calendarPersistConfig = {
//   key: 'calendar',
//   storage,
// };
