import React from 'react';
import PropTypes from 'prop-types';
import Loadable from '@loadable/component';
import Container from '../../base-components/container';

const Header = Loadable(() => import('../../components/header'));
const Footer = Loadable(() => import('../../components/footer'));

const ThisContainer = ({ children }) => {
  return (
    <>
      <Header />
      <Container maxWidth="xl">{children}</Container>
      <Footer />
    </>
  );
}

ThisContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThisContainer