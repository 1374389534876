import React from 'react';
import { Router } from '@reach/router';
import Loadable from '@loadable/component';
import { PublicRoute } from '../routes';
import LazyLoading from '../app/components/lazy-loading';
import * as Public from '../app/public';

const NotFound = Loadable(() => import('./404'), {
    fallback: <LazyLoading />,
});

const IndexPage = () => (
    <Router basepath="/">
        <NotFound path="*" />
        <PublicRoute
            path="/"
            component={Public.Home}
            title="PA - Página Inicial"
        />
    </Router>
);

export default IndexPage;
