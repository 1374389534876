import React from "react";
import { v4 as uuid } from 'uuid';
import { useDispatch } from 'react-redux';
import { postLessonplan } from '../../../../../../state/reducers/lessonplan';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import SaveTwoToneIcon from '../../../../../base-components/save-two-tone-icon';

const SaveLessonPlan = ({ post }) => {
    const dispatch = useDispatch();

    return (
        <div>
            {/* <span>Salvar Plano</span> */}
            <Tooltip title="Salvar Plano" placement="left">
                <IconButton
                    id={uuid()}
                    onClick={() => dispatch(postLessonplan(post))}
                    aria-label="save"
                >
                    <SaveTwoToneIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default SaveLessonPlan;