import React from 'react';
import { v4 as uuid } from 'uuid';
import Resources from '../resources';
import Typography from '../../../../../base-components/typography';
import ParagraphCardShowStrategies from '../../components/paragraph-card-show-strategies';
import ListDialogCardShowStrategies from '../../components/list-dialog-card-show-strategies';

const CardShowStrategies = ({ s }) => (
  <div key={uuid()}>
    <Typography paragraph>
      <i>Duração:</i> {s.time}
    </Typography>
    <Typography>
      <i>Orientação:</i>
    </Typography>
    <ParagraphCardShowStrategies>
      {s.guidance}
    </ParagraphCardShowStrategies>
    <Typography>
      <i>Propósito:</i>
    </Typography>
    <ParagraphCardShowStrategies>
      {s.purpose}
    </ParagraphCardShowStrategies>
    <Typography paragraph>
      <i>Fale com a turma:</i>
    </Typography>
    <ListDialogCardShowStrategies dialogue={s?.dialogue} />
    <Resources resources={s?.resources} />
  </div>
);

export default CardShowStrategies;
