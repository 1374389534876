import * as actions from './consts';

export const getLessonplan = (query) => ({
    type: 'API_METHOD_GET',
    subtype: actions.GET_LESSONPLAN,
    flag: actions.FLAG_LESSONPLAN_GET,
    path: `/lessonplan/${query}`,
});

export const getLessonplanView = (uuid) => ({
    type: 'API_METHOD_GET',
    subtype: actions.GET_LESSONPLAN_VIEW,
    flag: actions.FLAG_LESSONPLAN_GET_VIEW,
    path: `/lessonplan/${uuid}/view`,
    uuid,
});

export const postLessonplan = (payload) => ({
    type: 'API_METHOD_POST',
    subtype: actions.POST_LESSONPLAN,
    flag: actions.FLAG_LESSONPLAN_POST,
    state: 'lessonplan',
    intercept: 'lessonplan',
    path: '/lessonplan',
    payload,
});

export const postLessonplanPrint = (payload) => ({
    type: 'API_METHOD_REPORT',
    subtype: actions.POST_LESSONPLAN_PRINT,
    flag: actions.FLAG_LESSONPLAN_POST,
    state: 'lessonplan',
    intercept: 'report',
    path: '/lessonplan/report',
    payload,
});

export const putLessonplan = (uuid, payload) => ({
    type: 'API_METHOD_PUT',
    subtype: actions.PUT_LESSONPLAN,
    flag: actions.FLAG_LESSONPLAN_PUT,
    state: 'lessonplan',
    intercept: 'lessonplan',
    path: `/lessonplan/${uuid}`,
    uuid,
    payload,
});

export const delLessonplan = (uuid) => ({
    type: 'API_METHOD_DELETE',
    subtype: actions.DEL_LESSONPLAN,
    flag: actions.FLAG_LESSONPLAN_DEL,
    state: 'lessonplan',
    intercept: 'lessonplan',
    path: `/lessonplan/${uuid}`,
    uuid,
});

export const reportLessonplan = (payload) => ({
    type: 'API_METHOD_REPORT',
    subtype: actions.REPORT_LESSONPLAN,
    flag: actions.FLAG_LESSONPLAN_REPORT,
    state: 'lessonplan',
    intercept: 'report',
    path: '/lessonplan/report',
    payload,
});
