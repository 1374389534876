import React from "react";
import EditStrategiesFase from "../../components/edit-strategies-fase";
import RemoveStrategiesFase from "../../components/remove-strategies-fase";
import BarDotted from "../../../../../components/bar-dotted";

const BarStrategiesTools = ({ s, strategies, setStrategies, setEdit }) => {
    return (
        <BarDotted>
            {/* edit strategies fase */}
            <EditStrategiesFase setEdit={setEdit} s={s} />
            {/* remove strategies fase */}
            <RemoveStrategiesFase setStrategies={setStrategies} strategies={strategies} s={s} />
        </BarDotted>
    )
}

export default BarStrategiesTools;