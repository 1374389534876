import React from "react";
import { v4 as uuid } from 'uuid';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import HighlightOffTwoToneIcon from '../../../../../base-components/high-light-off-two-tone-icon';

const CancelStrategiesFase = (
    {
        setEdit
    }
) => {
    return (
        <div>
            {/* <span>Cancelar</span> */}
            <Tooltip title="Cancelar" placement="left">
                <IconButton
                    id={(uuid())}
                    onClick={() => setEdit([])}
                    aria-label="cancelar"
                >
                    <HighlightOffTwoToneIcon />
                </IconButton>
            </Tooltip>
        </div>)
}

export default CancelStrategiesFase;