/* eslint-disable react/prop-types */
import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { enquireScreen } from 'enquire-js';
import { setIsMobile } from '../../state/reducers/app/index';
import LazyLoading from '../../app/components/lazy-loading';
import Seo from '../../app/components/seo';

const DefaultLayout = lazy(() => import('./DefaultLayout'));

const PublicRoute = ({ component: Component, title, ...rest }) => {
    const dispatch = useDispatch();
    React.useMemo(() => {
        enquireScreen((b) => {
            dispatch(setIsMobile(!!b));
        });
    }, [dispatch]);
    return (
        <Suspense fallback={LazyLoading()}>
            <DefaultLayout>
                <>
                    <Seo title={title} />
                    <Component {...rest} />
                </>
            </DefaultLayout>
        </Suspense>
    );
};

PublicRoute.defaultProps = {
    title: 'Plano de Aula',
};

PublicRoute.propTypes = {
    title: PropTypes.string,
};

export default PublicRoute;
