import React from 'react';
import CardHeader from '../../../base-components/card-header'
import CardContent from '../../../base-components/card-content'
import Avatar from '../../../base-components/avatar'
// import { v4 as uuid } from 'uuid';
import PanelEditUser from '../panel-edit-user';
import Card from '../../../base-components/card';
import Container from '../../../base-components/container';
import './style.css';

export default ({ user }) => (
  <Container>
    <Card>
      <CardHeader
        avatar={<Avatar alt="Foto Perfil" src={user.picture} />}
        title={`${user.name} ${user.surname} `}
        subheader={user.userType === 'Teacher' && 'Professor'}
      />

      <CardContent>
        <PanelEditUser name={user.name} surname={user.surname} />
      </CardContent>
    </Card>
  </Container>
);
