import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '../../../base-components/make-styles'
import { v4 as uuid } from 'uuid';
import TextField from '../../../components/text-input';
import Button from '../../../components/button';
import Typography from '../../../components/typography';
import { putUser } from '../../../../state/reducers/user';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      // width: 'ch',
    },
  },
}));

export default function BasicTextFields({ name, surname }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [nameIn, setName] = useState(name);
  const [surnameIn, setSurname] = useState(surname);

  const updateName = () => {
    const payload = {
      name: nameIn,
      surname: surnameIn,
    };
    dispatch(putUser(payload));
    // console.log(payload);
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <Typography>
        <i>Mudar seu nome:</i>
      </Typography>
      <TextField
        id={uuid()}
        label="Nome"
        variant="outlined"
        value={nameIn}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        id={uuid()}
        label="Sobrenome"
        variant="outlined"
        value={surnameIn}
        onChange={(e) => setSurname(e.target.value)}
      />
      <div>
        <Button variant="outlined" onClick={() => updateName()}>
          Salvar
        </Button>
      </div>
    </form>
  );
}
