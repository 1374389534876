import * as defines from './consts';

import initialState from './initialState';

import decodeBase64 from './base64';

export default (state = initialState, action) => {
    const payloads = action.payload?.data?.token?.split('.');

    switch (action.type) {
    case defines.SIGNIN:
        return {
            ...state,
            islogged: true,
            error: false,
            user: action.payload || [],
            userData: JSON.parse(decodeBase64(payloads[1]) || []),
            jwt: action.payload.data.token || '',
        };

    case defines.ERROR_MSG:
        return { ...state, error: true, msg: action.payload.message || [] };

    case defines.RESET_STATE:
        return { ...state, error: false };

    case defines.LOGOUT:
        return {
            state: null,
        };
    default:
        return state;
    }
};
