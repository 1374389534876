import React from 'react';
import { v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux';
import setMenuList from './redux/actions';
import * as Styled from './styled';
import Link from '../link';
import Login from '../login';
import logo from '../../../images/logo2.png';
import IconButton from '../../base-components/icon-button';
import MenuIcon from '../../base-components/menu-icon';
import MenuOpenIcon from '../../base-components/menu-open-icon';

export default function ButtonAppBar() {
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state?.auth?.islogged);
  const isMobile = useSelector((state) => state.app.isMobile);
  const menulist = useSelector((state) => state.header.menulist);

  return (
    <Styled.Root>
      <div />
      <Styled.App>
        {!isMobile ? (
          /** Desktop */
          <div>
            <Styled.Title logo={logo} />
            {/* Home */}
            {isLogged && window.location.pathname === '/' && (
              <>
                <Link id={uuidv4()} to="/app" text="Começar" />
              </>
            )}
            {/* App */}
            {isLogged && window.location.pathname !== '/' && (
              <>
                <Link id={uuidv4()} to="/app" text="Procurar" />
                <Link id={uuidv4()} to="/app/calendar" text="Planejamento" />
                {/* <Link id={uuidv4()} to="/app/school" text="Escola" /> */}
                <Link id={uuidv4()} to="/app/settings" text="Configurações" />
              </>
            )}
            <Login isLogged={isLogged} />
          </div>
        ) : (
          /** Mobile */
          <div style={
            {
              display: 'flex',
              // alignContent: 'flex-end',
              flexDirection: 'column',
              // height: { menulist? '48px': '96px' },
              minWidth: '100%'
            }
          }>
            <div style={
              {
                display: 'flex',
                justifyContent: 'space-between',
                // flexDirection: 'row-reverse',
                height: '48px',
                minWidth: '100%'
              }
            }>
              <Styled.Title id={uuidv4()} logo={logo} />
              <IconButton
                id={uuidv4()}
                onClick={() => dispatch(setMenuList())}
                title='Menu'
              >
                {menulist ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
            </div>
            {/* Menu */}
            <div>
              {/* Home */}
              {menulist && isLogged && window.location.pathname === '/' && (
                <>
                  <Link id={uuidv4()} to="/app" text="Começar" />
                </>
              )}
              {/* App */}
              {menulist && isLogged && window.location.pathname !== '/' && (
                <>
                  <Link id={uuidv4()} to="/app" text="Procurar" />
                  <Link id={uuidv4()} to="/app/calendar" text="Planejamento" />
                  {/* <Link id={uuidv4()} to="/app/school" text="Escola" /> */}
                  <Link id={uuidv4()} to="/app/settings" text="Configurações" />

                </>
              )}
              {menulist && <Login isLogged={isLogged} />}
            </div>
          </div>
        )}
      </Styled.App>
    </Styled.Root>
  );
}
