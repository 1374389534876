import React from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from './autocomplete';
import LoadingLessonplan from './card-lesson-plan/loading';
import Card from '../../components/card';
import Container from '../../components/container';
import LoadLessonplan from './load-lessonplan';
// TODO: Adicionar um getplano,
// adicinar esses id desses lessonplan num array, criar um visualizador de planos selecinados... para exclusão ou edição
// se exclusão remove do array, se edição, salva como plano prŕoprio, sinalizado como co-autoria.

export default () => {
  const lessonplan = useSelector((state) => state.lessonplan);

  return (
    <>
      <Autocomplete />
      <br />
      <Container>
        <Card>
          {lessonplan?.get ? <LoadingLessonplan /> : <LoadLessonplan />}
        </Card>
      </Container>
    </>
  );
};
