import React from 'react';

import Skeleton from '../../../base-components/skeleton';

import { Container, Card } from './styled';

export default function () {
  return (
    <Container>
      <Card>
        <Skeleton width={310} />
        <br />
        <Skeleton width={210} animation={false} />
        <br />
        <br />
        <br />
        <Skeleton animation="wave" />
      </Card>
    </Container>
  );
}
