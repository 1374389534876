import React from "react";
import { v4 as uuid } from 'uuid';

const ListDialogCardShowStrategies = ({ dialogue }) => (
    <ul>
        {dialogue.map((p) => (
            <li key={uuid()}>{p}</li>
        ))}
    </ul>
)

export default ListDialogCardShowStrategies;