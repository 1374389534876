import * as comments from './consts';

import initialState from './initialStates';

export default (state = initialState, action) => {
    switch (action.type) {
    case comments.GET_COMMENTS:
        return { ...state, get: false, comments: action.payload || [] };

    case comments.GET_COMMENTS_VIEW:
        return {
            ...state,
            getview: false,
            // eslint-disable-next-line no-underscore-dangle
            [`${action.payload[0]._id}`]: action.payload || [],
        };

    case comments.POST_COMMENTS:
        return { ...state, post: false, comments: action.payload || [] };

    case comments.POST_COMMENTS_PRINT:
        return { ...state, post: false, print: action.payload || [] };

    case comments.PUT_COMMENTS:
        return { ...state, put: false, comments: action.payload || [] };

    case comments.DEL_COMMENTS:
        return { ...state, del: false, comments: action.payload || [] };

    case comments.FLAG_COMMENTS_GET:
        return { ...state, get: true };

    case comments.FLAG_COMMENTS_GET_VIEW:
        return { ...state, getview: true };

    case comments.FLAG_COMMENTS_POST:
        return { ...state, post: true };

    case comments.FLAG_COMMENTS_PUT:
        return { ...state, put: true };

    case comments.FLAG_COMMENTS_DEL:
        return { ...state, del: true };

    default:
        return state;
    }
};
