import React from "react";
import { v4 as uuid } from 'uuid';
import TextField from '../../../../../components/text-input';

const DuracaoTextFieldStrategiesFase = ({ setEdtTime, edttime }) => {
    return (
        <TextField
            id={uuid()}
            label="Duração"
            value={edttime}
            onChange={(e) => setEdtTime(e.target.value)}
        />
    )
}

export default DuracaoTextFieldStrategiesFase;