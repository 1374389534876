import React from 'react';
import Loadable from '@loadable/component';
import SkeletonRect from '../../../../components/skeleton-rect';

const Actions = Loadable(() => import('./actions'), {
  fallback: <SkeletonRect />,
});

export default [
  { field: 'id', headerName: 'UUID', width: 180 },
  {
    field: 'content',
    headerName: 'Comentário',
    width: 180,
  },
  {
    field: 'guest',
    headerName: 'Anônimo',
    width: 180,
    renderCell: (params) =>
      // eslint-disable-next-line react/destructuring-assignment
      params.row.guest ? <span>Sim</span> : <span>Não</span>,
  },
  { field: 'score', headerName: 'Pontuação', width: 160 },
  {
    field: 'actions',
    headerName: 'Ações',
    width: 420,
    renderCell: (params) => <Actions params={params} />,
  },
];
