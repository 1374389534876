// import React from "react";
import styled from "styled-components";
import Autocomplete from '../../base-components/autocomplete';

export const InputAutocomplete = styled(Autocomplete)`
  * {
    font-size: 0.75rem;
  }
  div {
    display: flex;
    justify-content: center;
    border-radius: 24px;
  }
  label {
    margin-left: 4px;
    font-size: 0.75rem;
    // transform: translate(14px, 10px) scale(1);
    border-radius: 24px;
  }
  /* .MuiAutocomplete-popper * {
    font-size: 0.75rem;
    border-radius: 24px;
  } */
  border-radius: 24px;
`;

export default InputAutocomplete;
