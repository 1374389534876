export default [
  {
    id: 1,
    content: 'Matemática',
  },
  {
    id: 2,
    content: 'Língua Portuguesa',
  },
  {
    id: 3,
    content: 'História',
  },
  {
    id: 4,
    content: 'Geografia',
  },
  {
    id: 5,
    content: 'Inglês',
  },
  {
    id: 6,
    content: 'Ciências',
  },
  {
    id: 7,
    content: 'Biologia',
  },
  {
    id: 8,
    content: 'Química',
  },
  {
    id: 9,
    content: 'Física',
  },
  {
    id: 10,
    content: 'Educação Física',
  },
  {
    id: 11,
    content: 'Espanhol',
  },
  {
    id: 12,
    content: 'Informática',
  },
  {
    id: 13,
    content: 'Dança/Teatro',
  },
  {
    id: 14,
    content: 'Cidadania, Moral e Ética',
  },
  {
    id: 15,
    content: 'Empreendedorismo',
  },
  {
    id: 16,
    content: 'Oficina de Negócios',
  },
  {
    id: 17,
    content: 'Arte',
  },
  {
    id: 18,
    content: 'Matemática I',
  },
  {
    id: 19,
    content: 'Matemática II',
  },
  {
    id: 20,
    content: 'Geometria',
  },
  {
    id: 21,
    content: 'Produção Textual',
  },
  {
    id: 22,
    content: 'Redação',
  },
];
