import React from "react";

const BarDotted = ({ children }) => (
    <div
        style={{
            display: 'flex',
            justifyContent: 'space-between',
            border: '0.2px solid',
            borderStyle: 'dotted',
            borderRadius: '16px',
            padding: '0px',
        }}
    >
        {children}
    </div>
)

export default BarDotted;