import React from 'react';
import Loadable from '@loadable/component';
import SkeletonRect from '../../../../components/skeleton-rect';

const Actions = Loadable(() => import('./actions'), {
  fallback: <SkeletonRect />,
});

export default [
  { field: 'name', headerName: 'Escola', width: 240 },
  { field: 'dir', headerName: 'Diretor(a)', width: 240 },
  {
    field: 'coord',
    headerName: 'Coordenador(a)',
    width: 240,
  },
  {
    field: 'actions',
    headerName: 'Ações',
    width: 240,
    renderCell: (params) => <Actions params={params} />,
  },
];
