import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../components/modal';
import { modifyCalendarEvents } from '../redux';
import { IconButtonEditEvent } from './styled';
import { Center } from '../../../../components/datagrid/styles/styled';
import IconButton from '../../../../base-components/icon-button';
import Tooltip from '../../../../components/tooltip';
import AutocompleteSchoolHeader from '../../../../components/autocomplete-school-header';
import AutocompleteChipsClasses from '../../../../components/autocomplete-chips-classes';
import AutocompleteChipsSubjects from '../../../../components/autocomplete-chips-subjects';
import TimePicker from '../../../../components/time-picker';
import { update } from './utils';
import SaveIcon from '../../../../base-components/save-icon';

export default ({ data }) => {
  const dispatch = useDispatch();
  const [handle, setHandle] = useState(false);
  const [subject, setSubject] = React.useState(
    data.event.extendedProps.schoolschedule[0].subject,
  );
  const [classes, setClasses] = React.useState(
    data.event.extendedProps.schoolschedule[0].class,
  );
  const [time, setTime] = React.useState(
    data.event.extendedProps.schoolschedule[0].time,
  );

  const lessonplanheader = useSelector(
    (state) => state.lessonplanheader.lessonplanheader,
  );
  const user = useSelector((state) => state.user.user);

  const SetAction = () => (
    <Tooltip title="Salvar" placement="right-end">
      <IconButton
        aria-label="salvar"
        onClick={() => {
          dispatch(
            modifyCalendarEvents(
              // eslint-disable-next-line no-underscore-dangle
              data.event.extendedProps.uuid,
              update(
                data.event.extendedProps.plan,
                data.event.title,
                data.event.startStr,
                data.event.endStr,
                [
                  {
                    url:
                      lessonplanheader.url ||
                      data.event.extendedProps.schoolschedule[0].url,
                    name:
                      lessonplanheader.name ||
                      data.event.extendedProps.schoolschedule[0].name,
                    dir:
                      lessonplanheader.dir ||
                      data.event.extendedProps.schoolschedule[0].dir,
                    coord:
                      lessonplanheader.coord ||
                      data.event.extendedProps.schoolschedule[0].coord,
                    prof: `${user.name} ${user.surname}`,
                    class: classes,
                    time,
                    subject,
                  },
                ],
              ),
            ),
          );
          setHandle(false);
        }}
      >
        <SaveIcon />
      </IconButton>
    </Tooltip>
  );

  const body = (
    <>
      <h3 id="simple-modal-title">Editar este evento?</h3>
      <Center>
        <AutocompleteSchoolHeader
          school={data.event.extendedProps.schoolschedule[0].name}
        />
        <AutocompleteChipsClasses
          label="Selecione uma classe/ano"
          classes={classes}
          setClasses={setClasses}
        />
        <AutocompleteChipsSubjects
          label="Selecione uma disciplina..."
          subject={subject}
          setSubject={setSubject}
        />
        <TimePicker time={time} setTime={setTime} />
      </Center>
    </>
  );

  const trigger = <IconButtonEditEvent onClick={() => setHandle(true)} />;

  return (
    <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
      {body}
    </Modal>
  );
};
