import React from 'react';
import { v4 as uuid } from 'uuid';
import InputAutocomplete from '../input-autocomplete';
import options from '../../utils/bnccobj';
import filterSearch from '../../utils/filterSearch';
import RenderChips from '../render-chips';
import TextField from '../text-input';

const ChipsBncc = ({ label, bncc, setBncc }) => (
  <InputAutocomplete
    id={uuid()}
    multiple
    options={options}
    getOptionLabel={(option) => option.content}
    filterOptions={(option, state) => {
      if (state.inputValue.length > 4) {
        return option.filter((o) => filterSearch(state.inputValue, o));
      }
      return { content: '--', id: 0 };
    }}
    renderOption={(option) => option.content}
    getOptionSelected={(option, curValue) => option === curValue}
    onChange={(event, newValue, reason) => {
      if (reason === 'select-option') {
        setBncc([...bncc, newValue[bncc.length].id]);
      }
    }}
    renderInput={(params) => (
      <TextField id={uuid()} {...params} label={label} value={bncc} />
    )}
    renderTags={(value, getTagProps) => (
      <RenderChips
        value={value}
        getTagProps={getTagProps}
        data={bncc}
        setData={setBncc}
      />
    )}
    fullWidth
    value={bncc.map((o) => {
      const content = { content: o };
      return content;
    })}
  />
);

export default ChipsBncc;
