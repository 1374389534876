import React from "react";
import { v4 as uuid } from 'uuid';
import TextField from '../../../../../components/text-input';

const AvaliacaoTextFieldLessonplan = ({ setAppraisal, appraisal }) => {
    return (
        <TextField
            id={uuid()}
            label="Avaliação"
            value={appraisal}
            onChange={(e) => setAppraisal(e.target.value)}
        />
    )
}

export default AvaliacaoTextFieldLessonplan;