import { put, all } from 'redux-saga/effects';
import * as apiWatcher from './watchers';

export function* testSaga() {
  console.log('Sagas is Okay!');
  yield put({ type: 'TEST' });
}

export function* rootSaga() {
  yield all([
    apiWatcher.getWatcherSaga(),
    apiWatcher.postWatcherSaga(),
    apiWatcher.putWatcherSaga(),
    apiWatcher.deleteWatcherSaga(),
    apiWatcher.signinWatchSaga(),
    apiWatcher.reportWatchSaga(),
    // add other watchers to the array
  ]);
}
