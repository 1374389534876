import React from 'react';
import { v4 as uuid } from 'uuid';
import Chip from '../../base-components/chip';

const RenderChips = ({ value, getTagProps, data, setData }) =>
  value.map((d, index) => {
    const { onDelete } = getTagProps({ index });

    return (
      <Chip
        id={uuid()}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        {...getTagProps({ index })}
        variant="outlined"
        color="primary"
        label={d.content}
        onDelete={(e) => {
          // delete resources[index];
          setData(data.filter((r) => r !== d.content));
          onDelete(e);
        }}
      />
    );
  });

export default RenderChips;
