import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../app/public/layout';

const DefaultLayout = ({ children }) => <Layout>{children}</Layout>;

DefaultLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

export default DefaultLayout;
