import React from "react";
import styled from "styled-components";
import PictureAsPdfIcon from '../../base-components/picture-as-pdf-icon';
import IconButton from '../../base-components/icon-button';
import Tooltip from '../../base-components/tool-tip';

const IconButtonPdfEvent = styled((props) => (
    <Tooltip title="Visualizar">
        <IconButton aria-label="visualizar" {...props}>
            <PictureAsPdfIcon />
        </IconButton>
    </Tooltip>
))`
    width: 16px;
    height: 16px;
    .MuiSvgIcon-root {
      font-size: 0.725rem;
    }
    &:hover {
      svg {
        color: #fff;
      }
      background-color: #1a252f;
    }
    & > div {
    }
  `;

export default IconButtonPdfEvent;