import React from "react";
import { v4 as uuid } from 'uuid';
import update from './utils';
import IconButton from '../../../../../base-components/icon-button';
import Tooltip from '../../../../../base-components/tool-tip';
import SaveTwoToneIcon from '../../../../../base-components/save-two-tone-icon';

const UpdateStrategies = (
    {
        strategies,
        setEdit,
        edit,
        edttime,
        edtguidance,
        edtpurpose,
        edtdialogue,
        edtresources
    }
) => {
    const updateStrategies = (edt) => {
        setEdit(update(strategies, edt));
        setEdit([]);
    };

    return (
        <div>
            {/* <span>Salvar</span> */}
            <Tooltip title="Salvar" placement="right">
                <IconButton
                    id={uuid()}
                    onClick={() =>
                        updateStrategies({
                            step: edit.step,
                            time: edttime,
                            guidance: edtguidance,
                            purpose: edtpurpose,
                            dialogue: edtdialogue,
                            resources: edtresources,
                        })
                    }
                    aria-label="salvar"
                >
                    <SaveTwoToneIcon />
                </IconButton>
            </Tooltip>
        </div>
    )
}

export default UpdateStrategies;