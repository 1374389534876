import React from 'react';
import { v4 as uuid } from 'uuid';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ContainerAutcomplete from '../container-autocomplete';
import InputAutocomplete from '../input-autocomplete';
import filterSearch from '../../utils/filterSearch';
import RenderChips from '../render-chips';
import TextField from '../text-input';

const filter = createFilterOptions();

const InputChipsResources = ({ label, resources, setResources }) => {
  const [options, setOptions] = React.useState(
    resources.map((o) => {
      const content = { content: o };
      return content;
    }),
  );

  return (
    <ContainerAutcomplete>
      <InputAutocomplete
        id={uuid()}
        // value={value}
        multiple
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        filterSelectedOptions
        options={options}
        getOptionLabel={(option) => option.content}
        filterOptions={(option, state) => {
          const filtered = filter(option, state);

          const { inputValue } = state;
          const filtro = option.filter((o) => filterSearch(inputValue, o));

          if (filtro.length > 3) {
            return filtro;
          }
          if (inputValue !== '') {
            filtered.push({
              content: inputValue,
            });
          }

          return filtered;
        }}
        renderOption={(option) => option.content}
        getOptionSelected={(option, curValue) => option === curValue}
        onChange={(event, newValue, reason) => {
          if (reason === 'select-option') {
            setResources([...resources, newValue[resources.length].content]);
            setOptions({
              ...options,
              content: newValue[resources.length].content,
            });
          }
        }}
        renderInput={(params) => (
          <TextField id={uuid()} {...params} label={label} />
        )}
        renderTags={(value, getTagProps) => (
          <RenderChips
            value={value}
            getTagProps={getTagProps}
            data={resources}
            setData={setResources}
          />
        )}
        value={resources.map((o) => {
          const content = { content: o };
          return content;
        })}
        fullWidth
      />
    </ContainerAutcomplete>
  );
};

export default InputChipsResources;
