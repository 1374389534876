/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Loadable from '@loadable/component';

const Calendar = Loadable(() => import('./calendar'));

export default () => (
  <div id="calendar-container">
    <Calendar />
    <br />
  </div>
);


