import React from 'react';
import { v4 as uuid } from 'uuid';
import { ModalBody, ModalTitle, ModalContent } from './styled';
import Modal from '../../base-components/modal';
import IconButton from '../../base-components/icon-button';
import Tooltip from '../../base-components/tool-tip';
import CloseIcon from '../../base-components/close-icon';

export default ({ children, trigger, state }) => {
  // const year = moment().year();

  const { handle, setHandle, SetAction } = state;

  const modalBody = (
    <ModalBody id={uuid()}>
      <ModalTitle id={uuid()}>
        <SetAction />
        <Tooltip title="Fechar" placement="left-end">
          <IconButton aria-label="fechar" onClick={() => setHandle(false)}>
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </ModalTitle>
      <ModalContent id={uuid()}>{children}</ModalContent>
    </ModalBody>
  );

  return (
    <>
      {trigger}
      <Modal
        id={uuid()}
        open={handle}
        onClose={() => setHandle(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {modalBody}
      </Modal>
    </>
  );
};
