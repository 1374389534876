import React from 'react';
import PropTypes from 'prop-types';
import Loadable from '@loadable/component';

const Header = Loadable(() => import('../../components/header'));
const Footer = Loadable(() => import('../../components/footer'));

const ThisLayout = ({ children }) => (
  <>
    <Header />
    <div>{children}</div>
    <Footer />
  </>
);

ThisLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThisLayout;
