import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from '../../../../components/modal';
import IconButton from '../../../../base-components/icon-button';
import Tooltip from '../../../../components/tooltip';
import { removeCalendarEvents } from '../redux';
import { IconButtonRemoveEvent } from './styled';
import DeleteIcon from '../../../../base-components/delete-icon';

export default ({ uuid }) => {
  const dispatch = useDispatch();
  const [handle, setHandle] = useState(false);

  const SetAction = () => (
    <Tooltip title="Deletar" placement="right-end">
      <IconButton
        aria-label="deletar"
        onClick={() => {
          dispatch(removeCalendarEvents(uuid));
          setHandle(false);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );

  const body = (
    <>
      <h3>Remover este evento da lista?</h3>
      <p>Esta ação não poderá ser desfeita!</p>
    </>
  );

  const trigger = <IconButtonRemoveEvent onClick={() => setHandle(true)} />;

  return (
    <Modal trigger={trigger} state={{ handle, setHandle, SetAction }}>
      {body}
    </Modal>
  );
};
