import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import TextField from '../../base-components/textfield';

export default styled(({ ...props }) => (
  <TextField id={uuid()} variant="outlined" fullWidth {...props} />
))`

  label {
    font-size: 0.75rem;
    border-radius: 16px;
  }
  input {
    font-size: 0.75rem;
    border-radius: 16px;
  }
  & > * {
    border-radius: 24px;
  }
  
`;
// & > * {
//     height: 36px;
//   }
// label {
//   transform: translate(14px, 10px) scale(1);
// }
