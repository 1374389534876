import React from "react";
import { v4 as uuid } from 'uuid';
import TextField from '../../../../../components/text-input';

const AssuntoTextFieldLessonplan = ({ setTitle, title }) => {
    return (
        <TextField
            id={uuid()}
            label="Assunto"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
        />
    )
}

export default AssuntoTextFieldLessonplan;