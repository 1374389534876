import React from "react";

const ParagraphCardShowStrategies = ({ children }) => (
    <p
        style={{
            textIndent: '1.5em',
            textAlign: 'justify',
            textJustify: 'inter-word',
        }}
    >
        {children}
    </p>
)

export default ParagraphCardShowStrategies;