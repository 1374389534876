import React from 'react';

import PropTypes from 'prop-types';

import { Layout } from '../../app/private';

const AuthLayout = ({ children }) => <Layout>{children}</Layout>;
AuthLayout.propTypes = {
    children: PropTypes.element.isRequired,
};

export default AuthLayout;
