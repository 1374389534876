import React from 'react';
import Delete from './modal-delete';
import Link from '../../../../../components/link';
import Tooltip from '../../../../../base-components/tool-tip';
import PageviewIcon from '../../../../../base-components/pageview-icon';

export default ({ params }) => (
  <>
    <Delete params={params} />

    <Link
      to={`/app/lessonplan/${params.row.plan}/view`}
      text={
        <Tooltip title="Visualizar" placement="right-end">
          <PageviewIcon aria-label="visualizar" />
        </Tooltip>
      }
    />
  </>
);
